<button mat-icon-button [matMenuTriggerFor]="userMenu">
  <mat-icon class="secondary-text">settings</mat-icon>
</button>
<mat-menu #userMenu="matMenu">
  <button mat-menu-item (click)="changeUserProfileData()">
    <mat-icon>person</mat-icon>
    <span>Profile</span>
  </button>
  <button mat-menu-item (click)="changePassword()">
    <mat-icon>lock_outlined</mat-icon>
    <span>Update your password</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="about()">
    <mat-icon>info</mat-icon>
    <span>About</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="logout()">
    <mat-icon>directions_run</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>