<ifm-application-form
    [data]="data"
    [documents]="docs"
    [form]="form"
    [isInProgress]="isInProgress"
    [parentObjectId]="currentApplicationId"
    (saveFormAction)="saveFormHandler($event)"
    (saveFilesAction)="saveFilesHandler($event)"
    (saveAllAction)="saveAllHandler($event)"
    (submitAction)="submitHandler($event)"
  ></ifm-application-form>