import { Component, Input } from "@angular/core";
import { VendorOfferData } from "../../../vendor-offer/domain/models/vendor-offer.model";

@Component({
  selector: "ifm-dashboard-vendor-offers",
  templateUrl: "./dashboard-vendor-offers.component.html",
  styleUrls: ["./dashboard-vendor-offers.component.scss"],
})
export class DashboardVendorOffersComponent {
  @Input() offers: VendorOfferData[];
}
