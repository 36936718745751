import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, combineLatest } from "rxjs";
import { Injectable } from "@angular/core";
import { map, take } from "rxjs/operators";
import { CustomerFacade } from "../../features/customer/domain/+state/customer.facade";
import { KbaNavigationService } from "../../features/kba/services/kba-navigation.service";
import { LoanFacade } from "../../features/loan/domain/+state/loan.facade";

@Injectable({
  providedIn: "root",
})
export class LoanAgreementSignedGuard implements CanActivate {
  constructor(
    private loanFacade: LoanFacade,
    private customerFacade: CustomerFacade,
    private kbaNavigationService: KbaNavigationService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (JSON.parse(sessionStorage.getItem("accountAgreementViewed"))) {
      return true;
    }
    return combineLatest([
      this.customerFacade.getCustomerCheckoutState$(), 
      this.loanFacade.getAgreementReadyLoans()
    ])
    .pipe(
      take(1),
      map(([checkoutState, pendingLoans]) => {
        if (pendingLoans?.length > 0 && !checkoutState.verifyKba) {
          this.kbaNavigationService.signLoanAgreement({ loanId: pendingLoans[0] })
          return false;
        }
        return true;
      })
    );
  }
}


