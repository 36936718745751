<h2 class="heading">{{entity?.dba || entity?.name}} Documents</h2>
<div class="card">
  <div class="icon">
    <mat-icon class="icon__content">description</mat-icon>
  </div>
  <div>
    <p class="card__text">Checkout all your monthly statements, agreements and other documents</p>
    <button mat-button
            class="secondary-text"
            (click)="onClickSeeAllDocuments()">SEE ALL DOCUMENTS</button>
  </div>
</div>
