import { Component, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { MfaFacade } from "./mfa.facade";
import { MfaChangePhoneNumberService } from "./services/mfa-change-phone-number.service";

@UntilDestroy()
@Component({
  selector: "ifm-register-mfa",
  templateUrl: "./mfa.component.html",
  providers: [
    MfaFacade,
    MfaChangePhoneNumberService,
  ],
})
export class MfaComponent implements OnInit {
  constructor(private facade: MfaFacade) {}

  ngOnInit(): void {
    this.facade.initActions();
  }
}
