import { NgModule } from "@angular/core";
import { SharedModule } from "projects/my/src/app/shared/shared.module";
import { ReviewMsaAgreementComponent } from "./review-msa-agreement.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    ReviewMsaAgreementComponent,
  ],
  exports: [ReviewMsaAgreementComponent],
})
export class ReviewMsaAgreementWidgetModule {}
