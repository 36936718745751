import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { CustomerKbaStatus, MessageService } from 'common';
import { catchError } from 'rxjs/operators';
import { CustomerKbaService } from '../../services/customer-kba.service';
import { CustomerFacade } from '../../../customer/domain/+state/customer.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KbaFormFacade } from './kba.form.facade';
import { KbaData, KbaFormData } from './model/kba-form.model';
import { KbaNavigationService } from '../../services/kba-navigation.service';

@UntilDestroy()
@Component({
  selector: 'ifm-kba-form',
  templateUrl: './kba-form.component.html',
  styleUrls: ['./kba-form.component.scss'],
  providers: [KbaFormFacade],

})
export class KbaFormComponent implements OnInit {

  constructor(
    private router: Router,
    private customerCheckoutService: CustomerKbaService,
    private messageService: MessageService,
    private customerFacade: CustomerFacade,
    private facade: KbaFormFacade,
    private kbaNavigationService: KbaNavigationService

  ) {
    sessionStorage.setItem('accountSetupViewed', JSON.stringify(true));
  }

  data: KbaFormData;
  submitEnabled = true;

  dataSet(data: KbaFormData) {
    this.data = data;
    this.form = this.facade.initForm(data.kbaConversation);
  }

  form: UntypedFormGroup;

  ngOnInit() {
    this.facade
      .initData()
      .pipe(untilDestroyed(this), catchError((err) => {
        this.messageService.error(err);
        return EMPTY;
      }))
      .subscribe((result) => {
        this.dataSet(result);
      });
  }

  submit() {
    if (!this.form.valid) {
      return;
    }

    this.customerFacade.clearCustomerCache();

    this.facade.save(this.form.value as KbaData)
      .subscribe({
        next: this.submitSuccess.bind(this),
        error: this.submitError.bind(this)
      });
  }

  private submitError(response: any) {
    if (response.error.error === 'InvalidOperationException') {
      this.customerCheckoutService.verifyAnswersError = response.error;
      this.submitEnabled = false;
      this.router.navigate(['account', 'setup', 'verify-identity-result'], { replaceUrl: true });
    } else {
      this.messageService.error(response.error);
    }
  }

  private submitSuccess(result: any) {
    if (result.results.status === CustomerKbaStatus.Success) {
      this.submitEnabled = false;

      if(result.customer?.pendingApplications?.length > 0) {
        this.kbaNavigationService.signApplicationAgreement({ applicationId: result.customer?.pendingApplications[0] })
      }
      else {
        this.router.navigate(['dashboard']);
      }

    } else if (result.results.status === CustomerKbaStatus.None) {
      this.data.kbaConversation = result.results.questions;
      this.form = this.facade.initForm(this.data.kbaConversation);
    }
  }
}