import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard.component";
import { CasesWidgetModule } from "./features/cases-widget/cases-widget.module";
import { DashboardVendorOffersModule } from "./features/dashboard-vendor-offers/dashboard-vendor-offers.module";
import { CompleteApplicationWidgetModule } from "./features/complete-application-widget/complete-application.module";
import { ApplicationCompletedWidgetModule } from "./features/application-completed-widget/application-completed.module";
import { DocumentsWidgetModule } from "./features/documents-widget/documents-widget.module";
import { OthersFinancingWidgetModule } from "./features/others-financing-widget/others-financing-widget.module";
import { DashboardActionsModule } from "./features/dashboard-actions/dashboard-actions.module";
import { PhoneNumberConfirmationReminderComponent } from "./features/phone-number-confirmation-reminder/phone-number-confirmation-reminder.component";
import { MfaModule } from "../mfa/mfa.module";
import { CompleteApplicationBannerComponent } from './features/complete-application-banner/complete-application-banner.component';
import { ReviewMsaAgreementWidgetModule } from "./features/review-msa-agreement/review-msa-agreement.module";

@NgModule({
  imports: [
    SharedModule,
    DashboardRoutingModule,
    CasesWidgetModule,
    DashboardVendorOffersModule,
    OthersFinancingWidgetModule,
    CompleteApplicationWidgetModule,
    ApplicationCompletedWidgetModule,
    ReviewMsaAgreementWidgetModule,
    DocumentsWidgetModule,
    DashboardActionsModule,
    MfaModule
  ],
  declarations: [
    DashboardComponent, 
    PhoneNumberConfirmationReminderComponent,
    CompleteApplicationBannerComponent
  ],
})
export class DashboardModule {}
