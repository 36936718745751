import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { Entity } from "projects/my/src/app/features/entity/domain/models/entity.model";
import { LitigationHintData } from "projects/my/src/app/features/litigation/domain/models/litigation.model";

@Component({
  selector: "ifm-cases-widget-search",
  templateUrl: "./cases-widget-search.component.html",
  styleUrls: ["./cases-widget-search.component.scss"],
})
export class CasesWidgetSearchComponent {
  form: UntypedFormGroup;
  showCasesFilter: boolean;

  @Input() entity: Entity;
  @Output() filter: EventEmitter<LitigationHintData>;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      search: "",
    });
    this.filter = new EventEmitter();
  }

  closeFilter() {
    this.filterData(null);
    this.showCasesFilter = false;
  }

  filterData(data: LitigationHintData) {
    this.filter.emit(data);
  }
}
