<div class="container">
  <mat-card [ngClass.gt-xs]="'card md'">
    <div fxLayout="row"
         fxLayoutAlign="space-between center"
         class="header">
      <div fxFlex="80">
      </div>
      <div fxFlex="20"
           align="end">
        <button class="dismiss-button"
                *ngIf="offer?.dismissable"
                mat-icon-button
                (click)="setDismiss()"
                matTooltip="dismiss">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <div fxLayout="row"
           fxLayoutAlign="space-between center">
        <div fxFlex="20">
          <img *ngIf="offer?.icon"
               class="avatar big"
               src="{{offer.icon}}">
        </div>
        <div fxLayout="column"
             fxLayoutAlign="start start"
             fxLayoutGap="8px"
             fxFlex="80">
          <span class="body">
            {{offer?.body}}
          </span>
          <span class="secondary-text small"
                *ngIf="offer?.disclaimer">*{{offer.disclaimer}}</span>
        </div>
      </div>
      <div fxLayout="row"
           fxLayoutAlign="start center"
           class="layout margin-top-sm">
        <div fxFlex="15"></div>
        <div *ngFor="let button of offer?.buttons"
             fxFlex="85">
          <button mat-button
                  (click)="link(button.link)"
                  class="action-button"
                  [ngClass]="{
          'normal': button.appearance === buttonAppearance.normal,
          'emphasized': button.appearance === buttonAppearance.emphasized}">
            {{button.label}}
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
