import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  AppLoaderInterceptor,
  CacheInterceptor,
  ConvertParamsToUrlInterceptor,
  ErrorsModule,
  MfaModule,
  OAuthInterceptor,
  OAuthService,
  RefreshTokenInterceptor,
} from "common";
import { CoreModule } from "./core/core.module";
import { DashboardModule } from "./features/dashboard/dashboard.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthService } from "./features/auth/infrastructure/auth.service";
import { AuthModule } from "./features/auth/auth.module";
import { SharedModule } from "./shared/shared.module";
import { ApplicationFormModule } from "./features/application-form/application-form.module";
import { KbaModule } from "./features/kba/kba.module";
import { RedirectionModule } from "./features/redirection/redirection.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    SharedModule,
    CoreModule,
    DashboardModule,
    AuthModule,
    ApplicationFormModule,
    KbaModule,
    MfaModule,
    RedirectionModule,
    ErrorsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppLoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConvertParamsToUrlInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    {
      provide: OAuthService,
      useClass: AuthService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() { }
}
