import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
  SignApplicationAgreementActionData,
  SignLoanAgreementActionData,
  VerifyIdentityResultActionData,
} from "../features/kba-begin/models/kba-actions.model";
import { Router } from "@angular/router";
import { NavigationService } from "projects/my/src/app/core/navigation/navigation.service";

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class KbaNavigationService {
  constructor(private navigationService: NavigationService, private router: Router) {}

  verifyIdentity() {
    this.router.navigate(["/account/setup/verify-identity"]);
  }

  signApplicationAgreement(data: SignApplicationAgreementActionData) {
    sessionStorage.setItem("applicationAgreementViewed", JSON.stringify(true))
    this.navigationService.navigateToPortal(`application/${data.applicationId}/setup/sign-agreement`);
  }

  signLoanAgreement(data: SignLoanAgreementActionData) {
    sessionStorage.setItem("accountAgreementViewed", JSON.stringify(true))
    this.navigationService.navigateToPortal(`account/${data.loanId}/setup/sign-agreement`);
  }

  verifyIdentityResult(data: VerifyIdentityResultActionData) {
    this.router.navigate([
      "/account/setup/verify-identity-result",
      { verifyAnswersError: data.errorDescription }
    ]);
  }
}
