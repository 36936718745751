import { Component, Input } from "@angular/core";
import {} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ButtonAppearance, VendorOfferData } from "projects/my/src/app/features/vendor-offer/domain/models/vendor-offer.model";

@Component({
  selector: "ifm-dashboard-vendor-offer-widget",
  templateUrl: "./dashboard-vendor-offer-widget.component.html",
  styleUrls: ["./dashboard-vendor-offer-widget.component.scss"],
})
export class DashboardVendorOfferWidgetComponent {
  @Input() offer: VendorOfferData;

  buttonAppearance = ButtonAppearance;

  constructor(private router: Router) {}

  link(link: string): void {
    if (link.startsWith("/")) this.router.navigate([link]);
    else if (link.startsWith("action:")) {
      const methodName = link.split(":")[1];
      if (this[methodName]) this[methodName]();
    } else window.open(link);
  }
}
