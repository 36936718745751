import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Uppy } from "@uppy/core";
import { DashboardOptions } from "@uppy/dashboard";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UploadFileDialogService } from './upload-file-dialog.service';
import { UploadFileDialogConfig, UploadFileDialogResult, UppyFileChangeTypeEnum } from './upload-file.model';
import { getUppyDashboardOptions } from '../progress-bar-dialog/document-upload-uppy-config';
 
@Component({
  selector: 'ifm-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss'],
  providers: [UploadFileDialogService]
})
export class UploadFileDialogComponent implements OnInit {
  public uppy: Uppy;
  public uppyDashboardOptions: DashboardOptions;
  
  private result: UploadFileDialogResult;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UploadFileDialogConfig,
    private dialog: MatDialogRef<UploadFileDialogComponent>,
    private service: UploadFileDialogService) {
      this.result = {} as UploadFileDialogResult;
      this.service.setMetaCallback(this.data.getMetaCallback);
    }

  public ngOnInit(): void {
    this.uppy = this.service.getUppy(this.data, this.result, this.dialog);
    this.uppyDashboardOptions = getUppyDashboardOptions();
    this.dialog.addPanelClass('upload-panel');
    this.handleUnwantedClose();
  }

  private handleUnwantedClose() {
    this.dialog.beforeClosed().subscribe(_ => {
      this.result = { type: UppyFileChangeTypeEnum.Closed, files: this.uppy.getFiles() };
    });
  }

  public static show(dialog: MatDialog, uploadConfig: UploadFileDialogConfig): Observable<UploadFileDialogResult> {
    const dialogRef = dialog.open(UploadFileDialogComponent, {
      width: '480px',
      data: { ...uploadConfig },
      closeOnNavigation: true,
    });

    return dialogRef.afterClosed().pipe(
      map(result => {
        if (result) {
          return result;
        }
        return dialogRef.componentInstance.result;
      })
    );
  }
}
