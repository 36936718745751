import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoanRemote } from "../../infrastructure/loan.remote";
import { AccountQueryParams, LoanData, LoanQueryResult } from "common";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoanFacade {
  constructor(private readonly remote: LoanRemote) {}

  getOtherFinancingLoansByEntity(entityId: number): Observable<LoanData[]> {
    const params = new AccountQueryParams({
      skip: 0,
      limit: 1000,
      active: true,
      excludedProductCode: "Law",
      entityId,
    });
    return this.query(params).pipe(map((results) => results?.values));
  }

  query(params: AccountQueryParams): Observable<LoanQueryResult> {
    return this.remote.query(params);
  }

  getAgreementReadyLoans(
    ): Observable<number[]> {
      return this.remote.getAgreementReadyLoans();
  }
}
