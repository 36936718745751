import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { ConfigService, MessageService, OAuthService } from "common";
import { BehaviorSubject, Observable, of, timer } from "rxjs";
import { catchError, delay, map, tap } from "rxjs/operators";
import { AppSettings } from "projects/my/src/app.settings";
import jwtDecode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthService extends OAuthService {
  sessionValidBs: BehaviorSubject<boolean | undefined>;

  constructor(
    http: HttpClient,
    router: Router,
    configService: ConfigService,
    private messageService: MessageService,
    private settings: AppSettings
  ) {
    super(configService, router, http);
    this.sessionValidBs = new BehaviorSubject<boolean | undefined>(undefined);
  }

  private headers = new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded",
  });

  public authorize(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (!!code) return;

    const body = this.getHttpRequestParams();

    window.location.href =
      `${this.settings.auth.oidc.authority}/authorize?` + body.toString();
  }

  public getHttpRequestParams(): HttpParams {
    let httpParams = new HttpParams()
      .set("response_type", "code")
      .set("redirect_uri", this.settings.auth.oidc.redirectUrl)
      .set("client_id", this.settings.auth.oidc.clientId)
      .set("scope", "openid offline_access");

    httpParams = this.enrichQueryParams(httpParams, "email");
    return httpParams;
  }

  public isSsoSessionValid(): Observable<boolean> {
    const current = this.sessionValidBs.getValue();
    if (current === undefined) {
      return this.http
        .get<boolean>(`${this.settings.auth.oidc.authority}/session`)
        .pipe(tap((valid) => this.sessionValidBs.next(valid)));
    }
    return of(current);
  }

  private enrichQueryParams(httpParams: HttpParams, label: string) {
    if (label && isLabelInUrl() && isOnlyOccurrence()) {
      const urlParams = new URLSearchParams(window.location.search);
      const value = urlParams.get(label);

      if (value) {
        httpParams = httpParams.set(label, value);
      }
    }
    return httpParams;

    function isOnlyOccurrence() {
      return window.location.search?.split(`${label}`)?.length === 2;
    }

    function isLabelInUrl() {
      return window.location.search?.includes(`${label}=`);
    }
  }

  public logout(): Observable<any> {
    return this.http.post(`${this.settings.auth.oidc.authority}/logout`, null, {
      headers: this.headers,
    });
  }

  public handleCallback(): Observable<boolean> {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (!code) {
      return of(false);
    }

    const body = new HttpParams()
      .set("code", code)
      .set("grant_type", "authorization_code")
      .set("redirect_uri", this.settings.auth.oidc.redirectUrl)
      .set("client_id", this.settings.auth.oidc.clientId);

    return this.http
      .post<any>(
        `${this.settings.auth.oidc.authority}/token`,
        body.toString(),
        { headers: this.headers }
      )
      .pipe(
        map((response) => {
          this.sessionBegin(
            "",
            response.access_token,
            response.expires_in,
            response.refresh_token,
            response.refresh_token_expires_in,
            this.settings.auth.oidc.clientId
          );
          return true;
        }),
        catchError((error) => {
          this.messageService.error(error);
          return of(false);
        })
      );
  }

  public getSessionId(): string {
    if (!this.accessToken) return null;
    const accessToken = jwtDecode<any>(this.accessToken);
    return accessToken.sso;
  }

  override restartIdleTimer(): void {
    this.sessionTimeoutClear();

    if (
      !sessionStorage.tokenExpiresAt ||
      !sessionStorage.refreshToken ||
      !sessionStorage.refreshTokenExpiresAt
    )
      return;
    // Expiration Date - Not Before ( we have no Issued At claim, but this is fine as well )
    const signOutDueTimeInMs =
      new Date(sessionStorage.refreshTokenExpiresAt).getTime() -
      new Date(sessionStorage.tokenExpiresAt).getTime();

    // RD: It but looks like timer does not support huge numbers, so it emits immediately. See https://github.com/ReactiveX/rxjs/issues/3015
    OAuthService.oauthSessionTimeout = timer()
      .pipe(delay(signOutDueTimeInMs))
      .subscribe(() => {
        this.router.navigate(["sign-out"], { replaceUrl: true });
      });
  }

  override refreshAuthToken(): Observable<any> {
    if (!sessionStorage.refreshToken) {
      this.sessionEndLocal();
      return of(null);
    }
    const body = this.transformRequest({
      grant_type: "refresh_token",
      client_id: sessionStorage.clientId,
      refresh_token: sessionStorage.refreshToken,
    });
    return this.http
      .post(`${this.settings.auth.oidc.authority}/token`, body, {
        headers: this.headers,
      })
      .pipe(
        tap((res) => {
          if (res?.access_token && (window as any)?.flutter_inappwebview) {
            (window as any).flutter_inappwebview.callHandler(
              "flutterTokensChanged",
              res
            );
          }
        })
      );
  }
}
