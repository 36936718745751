<mat-dialog-content>
  <header>
    <h2 class="heading">Case resolved?</h2>
  </header>
  <section class="content">
    <ifm-action-select-form-field [data]="data"
                                  (selectChange)="onSelectChange($event)">
    </ifm-action-select-form-field>
    <form [formGroup]="liquidityForm">
      <mat-form-field appearance="outline"
      class="full-width">
      <mat-label>Case resolved reason</mat-label>
      <mat-select formControlName="resolutionStatus"
          placeholder="Case resolved reason">

      <mat-option *ngFor="let status of resolutionStatuses"
            [value]="status.value">
      {{status.name}}
      </mat-option>
      </mat-select>
      </mat-form-field>
      <ifc-datepicker [fc]="liquidityForm.get('resolutionDate')" [label]="'Date of outcome'" [disabled]="liquidityForm.get('resolutionDate').disabled" [min]="minDate" [max]="maxDate"></ifc-datepicker>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Tell us more about the case</mat-label>
        <textarea matInput type="text" formControlName="note" rows="4"></textarea>
      </mat-form-field>
      <mat-checkbox appearance="outline" color="primary" formControlName="isConsented">I authorize LevelEsq to use my experience as a testimonial.</mat-checkbox>
    </form>

  </section>
  <footer class="actions">
    <button mat-button [mat-dialog-close]="">
      CANCEL
    </button>
    <button class="actions__submit"
            mat-button
            [disabled]="!selectedCaseData?.isValid || this.liquidityForm.invalid"
            (click)="onSubmit()">
      OK
    </button>
  </footer>
</mat-dialog-content>
