import { Component, Input, OnChanges } from "@angular/core";
import {
  LoanData,
  LoanHelper,
  LoanStatus,
  LoanStatusLabel,
  ProductCode,
} from "common";

@Component({
  selector: "ifm-other-financing-widget-info",
  templateUrl: "./other-financing-widget-info.component.html",
  styleUrls: ["./other-financing-widget-info.component.scss"],
})
export class OtherFinancingWidgetInfoComponent implements OnChanges {
  @Input() loan: LoanData;

  productName: string;
  balance: number;
  LoanStatusLabel = LoanStatusLabel;
  LoanStatus = LoanStatus;

  ngOnChanges(): void {
    this.initProductName(this.loan);
    this.balance = LoanHelper.getOutstandingBalance(this.loan);
  }

  private initProductName(loan: LoanData) {
    if (!loan) {
      return;
    }

    let name = null;
    if (loan?.productCode === ProductCode.LOC) {
      name = "Line of Credit";
    }
    if (loan?.productCode === ProductCode.Term) {
      name = "Term Loan";
    }

    const length = loan?.loanNumber?.length;
    let maskedLoanNumber = null;
    if (length > 4) {
      maskedLoanNumber = `*${loan.loanNumber.substring(length - 4)}`;
    }

    this.productName = `${name} ${maskedLoanNumber}`;
  }
}
