<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="28px" [ngClass]="'card xs'">
  <div>
    <ifc-avatar-icon [data]="entity?.advisorAvatar"></ifc-avatar-icon>
  </div>
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
    <div fxLayout="column" class="secondary-text">
      <span class="main-title">{{entity?.advisorName}}</span>
      <span class="mat-body-1">Business Advisor</span>
    </div>
    <div fxLayout="column" fxLayoutGap="12px">
      <div>
        <div (click)="$event.stopPropagation()">
          <a href="{{mailLink(entity?.advisorEmail)}}">{{entity?.advisorEmail}}</a>
        </div>
        <span class="secondary-text">{{advisorPhone | phone}}</span>
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="center center" class="layout padding-top-md">
  <button mat-stroked-button class="call-button" (click)="calendarLink()">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>phone_in_talk</mat-icon>
      <span>SCHEDULE A CALL</span>
    </div>
  </button>
</div>
