import { Injectable } from "@angular/core";
import { DashboardDataService } from "./services/dashboard-data.service";
import { Observable } from "rxjs";
import { DashboardData } from "./models/dashboard.model";
import { HandleActionsService } from "../../core/actions/handle-actions.service";

@Injectable()
export class DashboardFacade {
  constructor(
    private dashboardDataService: DashboardDataService,
    private handleActionsService: HandleActionsService
  ) {}

  getDashboardData$(): Observable<DashboardData> {
    return this.dashboardDataService.getDashboardData$();
  }

  initActions() {
    this.handleActionsService.initActions();
  }
}
