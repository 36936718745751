import { Injectable } from "@angular/core";
import { LocalStorageCachingStorageService } from "common";
import { CustomerFacade } from "../../features/customer/domain/+state/customer.facade";
import { AuthService } from "../../features/auth/infrastructure/auth.service";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private oauthService: AuthService,
    private customerFacade: CustomerFacade,
    private cache: LocalStorageCachingStorageService
  ) {}

  logout() {
    return this.oauthService.logout().pipe(tap(() => this.removeSession())).subscribe();
  }

  private removeSession(): void {
    this.oauthService.sessionEndLocal();
    this.customerFacade.clearCustomerCache();
    this.cache.clear();
  }
}
