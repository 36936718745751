import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { mapTo, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { ErrorsService } from "common";
import { MinimalLeadService } from "../../service/minimal-lead.service";

@Injectable({
  providedIn: "root",
})
export class MinimalLeadApplicationFormGuard implements CanActivate {
  constructor(
    private router: Router,
    private minimalLeadService: MinimalLeadService,
    private errorsService: ErrorsService
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.minimalLeadService.getNotSubmitted().pipe(
      tap((result: number) => {
        if (result === 0) {
          this.errorsService.showCustomErrorPage(
            "There are no application forms that have yet to be submitted.",
            "Warning"
          );
          this.router.navigate(["home"]);
        }
      }),
      mapTo(true)
    );
  }
}
