import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  DashboardActionData,
  DashboardActionSelectedValue,
  DrawFundsActionData,
} from "../../models/dashboard-actions.model";

@Component({
  selector: "ifm-draw-funds-dialog",
  templateUrl: "./draw-funds-dialog.component.html",
  styleUrls: ["./draw-funds-dialog.component.scss"],
})
export class DrawFundsDialogComponent {
  selectedData: DashboardActionSelectedValue;

  constructor(
    public dialogRef: MatDialogRef<DrawFundsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DashboardActionData
  ) {}

  onSubmit(): void {
    if (!this.selectedData.isValid) {
      return;
    }

    const data: DrawFundsActionData = {
      entityId: this.selectedData.entityId,
      loanId: this.selectedData.loanId,
    };

    this.dialogRef.close(data);
  }

  onSelectChange(data: DashboardActionSelectedValue) {
    this.selectedData = data;
  }
}
