<div class="balance">
  <ng-container [ngSwitch]="loan?.productCode">
    <ng-container *ngSwitchCase="ProductCode.Term">
      <ng-container *ngTemplateOutlet="term"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ProductCode.LOC">
      <ng-container *ngTemplateOutlet="loc"></ng-container>
    </ng-container>
  </ng-container>
  <div class="actions">
    <button mat-button
            class="actions__item"
            (click)="onClickDraw()"
            *ngIf="isDrawEnabled">
      <span>DRAW</span>
    </button>
    <button mat-button
            class="actions__item"
            (click)="onClickPay()"
            *ngIf="isPayEnabled">
      <span>PAY</span>
    </button>
  </div>
</div>

<ng-template #term>
  <ifc-bar-chart [data]="barChartData"
                 [options]="barChartOptions"
                 [style.height]="'60px'"
                 [style.width]="'120px'">
  </ifc-bar-chart>
</ng-template>
<ng-template #loc>
  <div>
    <span class="balance__highlighted">${{loan?.loanInfo?.availableFunds | shortNumber}}</span>
    <span class="balance__text"> Available</span>
  </div>
  <mat-progress-bar class="balance__progress-bar"
                    mode="determinate"
                    [value]="balanceUtilization">
  </mat-progress-bar>
</ng-template>
