<form [formGroup]="form"
      class="search">
  <ifm-cases-widget-search-input [fc]="form.get('search')"
                                 [entityId]="entity?.id"
                                 (enter)="showCasesFilter=$event"
                                 fxFlexFill
                                 (backAction)="closeFilter()"
                                 (clearInput)="filterData(null)"
                                 (autocompleteOptionSelected)="filterData($event)">
  </ifm-cases-widget-search-input>
</form>
