import { Injectable } from "@angular/core";
import { UppyFile } from "@uppy/core";
import { AppBarAction, ButtonColor, DialogActionData } from "common";
import {
  ApplicationFormStepEnum,
  RequiredDocument,
  RequiredDocumentFileUploadModel
} from "../../model/application-form.model";

@Injectable()
export class ApplicationFormFacade {

  public provideDialogData(): DialogActionData {
    return {
      body:
        "All unsaved changes will be lost. Would you like to save your changes before leaving?",
      title: "Unsaved changes",
      buttons: [
        {
          label: "CANCEL",
          buttonColor: ButtonColor.Default,
          action: undefined,
        },
        {
          label: "DISCARD",
          buttonColor: ButtonColor.Default,
          action: false,
        },
        { label: "SAVE", buttonColor: ButtonColor.Primary, action: true },
      ],
    };
  }

  public getActions(step: ApplicationFormStepEnum): AppBarAction[] {
    switch (step) {
      case ApplicationFormStepEnum.Form:
        return [
          { id: "saveForm", label: "Save", buttonType: "button" },
          {
            id: "next",
            label: "Next",
            buttonType: "submit",
            buttonAppearance: "flat",
            buttonColor: "primary",
          },
        ];
      case ApplicationFormStepEnum.FileUpload:
        return [
          { id: "back", label: "Back", buttonType: "button" },
          { id: "saveFiles", label: "Save", buttonType: "button" },
          {
            id: "submit",
            label: "Submit",
            buttonType: "submit",
            buttonAppearance: "flat",
            buttonColor: "primary",
          },
        ];
        default: {
        console.warn("Wrong step state");
          return [];
        }
    }
  }

  public attachFilesToUpload(
    requiredDocument: RequiredDocument,
    newFiles: UppyFile[],
    filesToUpload: RequiredDocumentFileUploadModel[]
  ) : RequiredDocumentFileUploadModel[] {
    let result = this.updateFilesToUpload(requiredDocument, newFiles, filesToUpload);
    result = this.clearOutEmptyUploadFiles(result);
    return result;
  }

  private updateFilesToUpload(
    requiredDocument: RequiredDocument,
    newFiles: UppyFile[],
    filesToUpload: RequiredDocumentFileUploadModel[]
  ) : RequiredDocumentFileUploadModel[] {
    let result = [];

    if (!requiredDocument) {
      return result;
    }

    result = [...filesToUpload];

    const found = result.find(
      (c) => c.requiredDocument.id === requiredDocument.id
    );
    if (found) {
      found.filesToUpload = newFiles;
    } else {
      result.push({
        requiredDocument: requiredDocument,
        filesToUpload: newFiles,
      } as RequiredDocumentFileUploadModel);
    }

    return result;
  }

  private clearOutEmptyUploadFiles(
    filesToUpload: RequiredDocumentFileUploadModel[]
  ) : RequiredDocumentFileUploadModel[] {
    const result = [...filesToUpload];
    const noFileCheckouts = result.filter(
      (c) => !c.filesToUpload || c.filesToUpload.length === 0
    );
    noFileCheckouts.forEach((noFileCheckout) => {
      result.splice(result.indexOf(noFileCheckout), 1);
    });

    return result;
  }
}
