import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { applicationFormRoutes } from "./application-form.routes";
import { ApplicationFormComponent } from "./component/application-form/application-form.component";
import { AdditionalInfoApplicationComponent } from "./component/additional-info-application/additional-info-application.component";
import { RequiredDocumentsApplicationComponent } from "./component/required-documents-application/required-documents-application.component";
import { CommonModule } from "common";
import { MinimalLeadService } from "./service/minimal-lead.service";
import { AddressFormComponent } from './component/address-form/address-form.component';
import { UploadDocumentListItemComponent } from './component/upload-document-list-item/upload-document-list-item.component';
import { UploadFileDialogComponent } from "./component/upload-file-dialog/upload-file-dialog.component";
import { ProgressBarDialogComponent } from "./component/progress-bar-dialog/progress-bar-dialog.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { MsaApplicationService } from "./service/msa-application.service";
import { MinimalLeadWrapperComponent } from './component/minimal-lead-wrapper/minimal-lead-wrapper.component';
import { MsaApplicationWrapperComponent } from './component/msa-application-wrapper/msa-application-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(applicationFormRoutes),
    UppyAngularDashboardModule
  ],
  declarations: [
    ApplicationFormComponent,
    AdditionalInfoApplicationComponent,
    RequiredDocumentsApplicationComponent,
    AddressFormComponent,
    UploadDocumentListItemComponent,
    UploadFileDialogComponent,
    ProgressBarDialogComponent,
    MinimalLeadWrapperComponent,
    MsaApplicationWrapperComponent
  ],
  providers: [
    MinimalLeadService,
    MsaApplicationService
  ],
  exports: [RouterModule],
})
export class ApplicationFormModule {}
