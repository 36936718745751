import { Injectable } from "@angular/core";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";
import { LitigationFacade } from "../../litigation/domain/+state/litigation.facade";
import { concatMap, map, mergeMap, toArray } from "rxjs/operators";
import { Observable, combineLatest, forkJoin, from } from "rxjs";
import { DashboardData, DashboardEntityData } from "../models/dashboard.model";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";
import { MinimalLeadService } from "../../application-form/service/minimal-lead.service";
import { Entity } from "../../entity/domain/models/entity.model";

@Injectable()
export class DashboardDataService {
  constructor(
    private entityFacade: EntityFacade,
    private litigationFacade: LitigationFacade,
    private loanFacade: LoanFacade,
    private minimalLeadService: MinimalLeadService
  ) {}

  getDashboardData$(): Observable<DashboardData> {
    return combineLatest([
      this.getEntitiesData(),
      this.minimalLeadService.getNotSubmitted(),
    ]).pipe(
      map(([entitiesData, notSubmittedLead]) => ({
        entitiesData,
        existsNotSubmittedApplication: !!notSubmittedLead,
      }))
    );
  }

  private getEntitiesData(): Observable<DashboardEntityData[]> {
    return this.entityFacade.getEntities$().pipe(
      mergeMap((entities: Entity[]) => {
        return from(entities).pipe(
          concatMap((entity) => this.getDataPerEntity(entity)),
          toArray()
        );
      })
    );
  }

  private getDataPerEntity(
    entity: Entity
  ): Observable<DashboardEntityData> {
    return forkJoin([
      this.litigationFacade.getByEntity(entity.id),
      this.loanFacade.getOtherFinancingLoansByEntity(entity.id),
    ]).pipe(
      map(([cases, otherFinancingLoans]) => ({
        cases,
        otherFinancingLoans,
        entity,
      }))
    );
  }
}
