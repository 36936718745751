import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { BrandingService, CompanyBranding, MetaService } from "common";
import { catchError, filter } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EMPTY } from "rxjs";

declare global {
  interface Window {
    analytics: any;
  }
}

@UntilDestroy()
@Component({
  selector: "ifm-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  brandingData: any;

  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: MetaService,
    private brandingService: BrandingService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) {
    this.setDOMElements();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        window.analytics.page();
      });
    this.initBranding();
  }

  private initBranding() {
    this.brandingService.getBranding$()
      .pipe(
        untilDestroyed(this),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      ).subscribe(data => {
        this.initBrandingTheme(data);
        this.setDOMElements();
      });
  }

  private initBrandingTheme(brandingData: CompanyBranding) {
    if (brandingData?.colorSchema === 'levelesq') {
      this.renderer.setAttribute(
        this.document.body,
        'class',
        'theme-level-esq'
      );
    }
  }

  private setDOMElements(): void {
    this.titleService.setTitle("Business Financing | LevelEsq");

    this.metaService.createMetaTag(
      "description",
      `Welcome to your account. You can access information about your business with us here.`
    );
    // TODO[init] levelesq?
    this.metaService.createAlternateURL("https://portal.levelesq.com");
  }
}
