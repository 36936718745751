<div class="container">
  <mat-form-field appearance="outline">
    <button type="button" mat-button matPrefix mat-icon-button aria-label="Back" (click)="back()">
      <mat-icon>{{leftIcon}}</mat-icon>
    </button>
    <input type="text" matInput [formControl]="fc" [matAutocomplete]="autocomplete" placeholder="{{label}}"
      [readonly]="readonly" (focus)="onFocus()" (blur)="onBlur()">
      <button type="button" [ngClass]="{'hide': !fc.value || readonly }" mat-button matSuffix mat-icon-button aria-label="Clear"
        (click)="valueClear()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="autocompleteDisplayWith"
        (optionSelected)="autocompleteOptionSelected.emit($event.option.value)">
        <mat-option *ngFor="let option of optionsFiltered | async" [value]="option">
          <img *ngIf="showAvatar" height="30" width="30" matListAvatar src="../../../assets/profile.jpg" alt="Avatar">
          <span> {{optionLabel(option)}}</span>
        </mat-option>
      </mat-autocomplete>
  </mat-form-field>
</div>
