import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, combineLatest } from "rxjs";
import { Injectable } from "@angular/core";
import { map, take } from "rxjs/operators";
import { CustomerFacade } from "../../features/customer/domain/+state/customer.facade";
import { ApplicationFacade } from "../../features/application/domain/+state/application.facade";
import { KbaNavigationService } from "../../features/kba/services/kba-navigation.service";

@Injectable({
  providedIn: "root",
})
export class ApplicationAgreementSignedGuard implements CanActivate {
  constructor(
    private applicationFacade: ApplicationFacade,
    private customerFacade: CustomerFacade,
    private kbaNavigationService: KbaNavigationService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (JSON.parse(sessionStorage.getItem("applicationAgreementViewed"))) {
      return true;
    }
    return combineLatest([
      this.customerFacade.getCustomerCheckoutState$(), 
      this.applicationFacade.getAgreementReadyApplications()
    ])
    .pipe(
      take(1),
      map(([checkoutState, pendingApplications]) => {
        if (pendingApplications?.length > 0 && !checkoutState.verifyKba) {
          this.kbaNavigationService.signApplicationAgreement({ applicationId: pendingApplications[0] })
          return false;
        }
        return true;
      })
    );
  }
}


