import { FormGroup, FormControl, Validators } from "@angular/forms";
import { LawyerInfoForm, LawFirmInfoForm, AddressForm } from "../../model/application-form.model";

export class AppFormValidationData {
  public static firstNameMaxLength: number = 20;
  public static lastNameMaxLength: number = 20;
  public static ssnMinLength: number = 9;
  public static ssnMaxLength: number = 11;
  public static addressLine1MinLength: number = 2;
  public static addressLine1MaxLength: number = 60;
  public static addressLine2MaxLength: number = 10;
  public static cityMinLength: number = 2;
  public static cityMaxLength: number = 60;
  public static cityPattern: RegExp = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
  public static zipMaxLength: number = 9;
  public static zipPattern: RegExp = /^\d{5}(-?\d{4})?$/;
  public static phoneNumberMinLength: number = 9;
  public static phoneNumberMaxLength: number = 10;
  public static dbaMaxLength: number = 160;
  public static einMaxLength: number = 10;
}

export const getApplicationFormGroup = () => {
  return {
    lawyerInfo: new FormGroup<LawyerInfoForm>({
      firstName: new FormControl(undefined, {
        validators: [
          Validators.required,
          Validators.maxLength(AppFormValidationData.firstNameMaxLength),
        ],
      }),
      lastName: new FormControl(undefined, {
        validators: [
          Validators.required,
          Validators.maxLength(AppFormValidationData.lastNameMaxLength),
        ],
      }),
      ssn: new FormControl(undefined, {
        validators: [
          Validators.required,
          Validators.minLength(AppFormValidationData.ssnMinLength),
          Validators.maxLength(AppFormValidationData.ssnMaxLength),
        ],
      }),
      dateOfBirth: new FormControl(undefined, {
        validators: [Validators.required],
        updateOn: "blur",
      }),
      yearOfBarAdmission: new FormControl(undefined, {
        validators: [Validators.required],
      }),
      address: getApplicationAddressFormGroup(),
    }),
    entityInfo: new FormGroup<LawFirmInfoForm>({
      yearInBusiness: new FormControl(undefined, {
        validators: [Validators.required],
      }),
      company: new FormControl(undefined, {
        validators: [Validators.required],
      }),
      phoneNumber: new FormControl(undefined, {
        validators: [
          Validators.required,
          Validators.minLength(AppFormValidationData.phoneNumberMinLength),
          Validators.maxLength(AppFormValidationData.phoneNumberMaxLength),
        ],
      }),
      dba: new FormControl(undefined, {
        validators: [Validators.maxLength(AppFormValidationData.dbaMaxLength)],
      }),
      ein: new FormControl(undefined, {
        validators: [
          Validators.required,
          Validators.maxLength(AppFormValidationData.einMaxLength),
        ],
      }),
      address: getApplicationAddressFormGroup(),
    }),
  };
};

const getApplicationAddressFormGroup = () => {
  return new FormGroup<AddressForm>({
    line1: new FormControl(undefined, {
      validators: [
        Validators.required,
        Validators.minLength(AppFormValidationData.addressLine1MinLength),
        Validators.maxLength(AppFormValidationData.addressLine1MaxLength),
      ],
    }),
    line2: new FormControl(undefined, {
      validators: [
        Validators.maxLength(AppFormValidationData.addressLine2MaxLength),
      ],
    }),
    city: new FormControl(undefined, {
      validators: [
        Validators.required,
        Validators.pattern(AppFormValidationData.cityPattern),
        Validators.minLength(AppFormValidationData.cityMinLength),
        Validators.maxLength(AppFormValidationData.cityMaxLength),
      ],
    }),
    state: new FormControl(undefined, {
      validators: [Validators.required],
    }),
    zip: new FormControl(undefined, {
      validators: [
        Validators.required,
        Validators.pattern(AppFormValidationData.zipPattern),
        Validators.maxLength(AppFormValidationData.zipMaxLength),
      ],
    }),
  });
};