import { FormControl, FormGroup } from "@angular/forms";
import { UppyFile } from "@uppy/core";
import { Address } from "common";
import { MatDialog } from "@angular/material/dialog";

export interface SaveActionDataApplicationForm {
  formData: MinimalApplicationData;
}

export interface SaveFilesActionDataApplicationForm {
  files: RequiredDocumentFileUploadModel[];
  dialog: MatDialog;
}

export interface SubmitActionDataApplicationForm
  extends SaveFilesActionDataApplicationForm {}

export interface SaveAllActionDataApplicationForm
  extends SaveActionDataApplicationForm,
    SaveFilesActionDataApplicationForm {
  navBack: boolean;
}

export interface ApplicationFormValidity {
  isFormValid: boolean;
  isFileListValid: boolean;
}

export enum RefreshTypeApplicationFromEnum {
  RefreshTypeForm = "refresh-form",
  RefreshTypeFiles = "refresh-files",
  RefreshTypeAll = "refresh-all",
}

export enum ApplicationFormStepEnum {
  Form = 0,
  FileUpload = 1,
}

export interface MinimalApplicationData {
  lawyerInfo: LawyerInfo;
  entityInfo: LawFirmInfo;
}

export interface LawyerInfo {
  firstName: string;
  lastName: string;
  ssn: string;
  dateOfBirth: string;
  yearOfBarAdmission: number;
  address: Address;
}

export interface LawFirmInfo {
  yearInBusiness: number;
  company: string;
  phoneNumber: string;
  dba: string;
  ein: string;
  address: Address;
}

export interface LawyerInfoForm {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  ssn: FormControl<string>;
  dateOfBirth: FormControl<string>;
  yearOfBarAdmission: FormControl<number>;
  address: FormGroup<AddressForm>;
}

export interface LawFirmInfoForm {
  yearInBusiness: FormControl<number>;
  company: FormControl<string>;
  phoneNumber: FormControl<string>;
  dba: FormControl<string>;
  ein: FormControl<string>;
  address: FormGroup<AddressForm>;
}

export interface AddressForm {
  line1?: FormControl<string>;
  line2?: FormControl<string>;
  city?: FormControl<string>;
  state?: FormControl<string>;
  zip?: FormControl<string>;
}

export interface MinimalApplicationForm {
  lawyerInfo: FormGroup<LawyerInfoForm>;
  entityInfo: FormGroup<LawFirmInfoForm>;
}

export interface RequiredDocument {
  id: number;
  name: string;
  notes: string;
  requiredDocument: string;
  documents: any;
  category: string;
  requiredDocumentsCount: number;
  fileName: string;
}

export interface RequiredDocumentFileUploadModel {
  requiredDocument: RequiredDocument;
  filesToUpload: UppyFile[];
}

export type DataAndDocumentsArray = [
  data: MinimalApplicationData,
  documents: RequiredDocument[]
];


export interface ApplicationAndDataModel {
  data: MinimalApplicationData;
  docs: RequiredDocument[];
}


export interface RequiredDocumentMeta {
  parentObjectId: number;
  requirementDocumentId: number;
  category: string;
}
