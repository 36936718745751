import { Component, Input } from '@angular/core';
import { KbaNavigationService } from '../../../kba/services/kba-navigation.service';
import { AgreementStatus } from '../../../entity/domain/models/entity.model';
import { MsaAgreementMessages } from '../../const/data/dashboard-data';

@Component({
  selector: 'ifm-review-msa-agreement',
  templateUrl: './review-msa-agreement.component.html',
  styleUrls: ['./review-msa-agreement.component.scss']
})
export class ReviewMsaAgreementComponent {
  @Input() applicationId: number;
  @Input() msaAgreementStatus: AgreementStatus;

  readonly AgreementStatus = AgreementStatus;
  readonly MsaAgreementMessages = MsaAgreementMessages;

  constructor(private kbaNavigationService: KbaNavigationService) { }

  reviewMsaAgreement() {
    this.kbaNavigationService.signApplicationAgreement({ applicationId: this.applicationId })
  }
}
