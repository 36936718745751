import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  DashboardActionData,
  DashboardActionSelectedValue
} from "../../models/dashboard-actions.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DateHelper, EnumHelper, ResolutionStatus } from "common";
import { ResolvedActionData } from "projects/my/src/app/core/actions/actions.model";

@Component({
  selector: "ifm-resolved-dialog",
  templateUrl: "./resolved-dialog.component.html",
  styleUrls: ["./resolved-dialog.component.scss"],
})
export class ResolvedDialogComponent {
  liquidityForm: UntypedFormGroup;
  selectedCaseData: DashboardActionSelectedValue;
  resolutionStatuses = EnumHelper.getNamesAndValues(ResolutionStatus);
  minDate: Date;
  maxDate: Date;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ResolvedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DashboardActionData
  ) {
    let today = new Date();
    today = DateHelper.setToStartOfDay(today);
    this.maxDate = today;

    const monthAgo = new Date();
    monthAgo.setDate(today.getDate() - 30);
    this.minDate = monthAgo;

    this.liquidityForm = this.formBuilder.group({
      resolutionStatus: [undefined, Validators.required],
      resolutionDate: [today, Validators.required],
      note: [undefined],
      isConsented: [false],
    });
    this.liquidityForm.disable();
  }

  onSubmit(): void {
    if (!this.selectedCaseData.isValid || this.liquidityForm.invalid) {
      return;
    }

    const data: ResolvedActionData = {
      caseId: this.selectedCaseData.caseId,
      loanId: this.selectedCaseData.loanId,
      resolutionStatus: this.liquidityForm.controls.resolutionStatus.value,
      resolutionDate: this.liquidityForm.controls.resolutionDate.value,
      note: this.liquidityForm.controls.note.value,
      isConsented: this.liquidityForm.controls.isConsented.value,
    };

    this.dialogRef.close(data);
  }

  onSelectChange(data: DashboardActionSelectedValue) {
    this.selectedCaseData = data;

    if (data.isValid) {
      this.liquidityForm.enable();
    }
    else {
      this.liquidityForm.disable();
    }
  }
}
