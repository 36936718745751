<div class="container">
  <div class="card"
       *ngIf="true">
       <h2 class="heading">Complete your application</h2>

       <span class="text">Speed up the application review by securely providing the required information.</span>
       <div class="actions">
        <button mat-raised-button type="submit" (click)="completeApplication()" color="primary">COMPLETE APPLICATION</button>
       </div>
</div>
