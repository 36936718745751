import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppPageComponent, AppBarComponent, SyncGuardHelper } from "common";
import { DashboardComponent } from "./dashboard.component";
import { UserMenuComponent } from "../../shared/user-menu/user-menu.component";
import { IsLeadCompletedGuard } from "../../shared/guard/lead-completed.guard";
import { AuthGuard } from "../auth/infrastructure/auth.guard";
import { ApplicationAgreementSignedGuard } from "../../shared/guard/application-agreement-signed.guard";
import { PhoneNumberConfirmationReminderComponent } from "./features/phone-number-confirmation-reminder/phone-number-confirmation-reminder.component";
import { KbaGuard } from "../kba/features/kba-begin/guards/kba-begin.guard";
import { LoanAgreementSignedGuard } from "../../shared/guard/loan-agreement-signed.guard";

const routes: Routes = [
  {
    path: "dashboard",
    component: AppPageComponent,
    data: {
      appBar: {
        logoSize: "logo-s",
        logoIcon: true,
      },
      appPage: {
        contentClass: "simple",
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "right",
            component: UserMenuComponent,
          },
          {
            path: '',
            outlet: 'reminder',
            component: PhoneNumberConfirmationReminderComponent,
          }
        ],
      },
      {
        path: "",
        component: DashboardComponent,
        canActivate: [SyncGuardHelper],
        data: {
            syncGuards: [
            AuthGuard,
            IsLeadCompletedGuard,
            KbaGuard,
            ApplicationAgreementSignedGuard,
            LoanAgreementSignedGuard
          ]
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
