import { Component } from "@angular/core";
import { CompleteApplicationActionsService } from "./services/complete-application-actions.service";

@Component({
  selector: "ifm-complete-application-widget",
  templateUrl: "./complete-application-widget.component.html",
  styleUrls: ["./complete-application-widget.component.scss"],
})
export class CompleteApplicationWidgetComponent {
  constructor(private actions: CompleteApplicationActionsService) {}

  completeApplication() {
    this.actions.completeApplication();
  }
}
