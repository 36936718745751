import { UppyFile } from "@uppy/core";
import { RequiredDocumentMeta } from "../../model/application-form.model";

export enum UppyFileChangeTypeEnum {
  Added = 'added',
  Removed = 'removed',
  Canceled = "canceled",
  Closed = "closed"
}

export interface UploadFileDialogResult {
  type: UppyFileChangeTypeEnum;
  files: UppyFile[];
}

export interface UploadFileDialogConfig {
  accessToken?: string;
  endpoint?: string;
  files?: any[];
  getMetaCallback?: (data: UploadFileDialogConfig) => RequiredDocumentMeta;
}
