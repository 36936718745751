import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { VendorOfferData } from "../domain/models/vendor-offer.model";
import { AppSettings } from "projects/my/src/app.settings";

@Injectable({ providedIn: "root" })
export class VendorOfferRemote {
  constructor(private http: HttpClient, private settings: AppSettings) {}

  get(): Observable<VendorOfferData[]> {
    return this.http.get<VendorOfferData[]>(
      `${this.settings.messages.url}/api/vendor-offers`
    );
  }
}
