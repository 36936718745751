import { Component, OnInit } from "@angular/core";
import {
  AppBarComponentData,
  ConfirmNewPhoneNumberResponse,
  DialogActionComponent,
  DialogActionData,
  HiddenPhonePipe,
  MessageService,
} from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { catchError } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { CustomerFacade } from "../../../customer/domain/+state/customer.facade";

@UntilDestroy()
@Component({
  selector: "ifm-phone-number-confirmation-reminder",
  templateUrl: "./phone-number-confirmation-reminder.component.html",
  styleUrls: ["./phone-number-confirmation-reminder.component.scss"],
})
export class PhoneNumberConfirmationReminderComponent implements OnInit {
  oldPhoneNumber: number;
  newPhoneNumber: number;
  waitingForConfirmation: boolean;
  customerId: number;
  appBarComponentData: AppBarComponentData;

  constructor(
    private customerFacade: CustomerFacade,
    private messageService: MessageService,
    private dialog: MatDialog,
    private hiddenPhonePipe: HiddenPhonePipe
  ) {}

  ngOnInit() {
    this.customerFacade
      .getCurrentCustomer$()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.oldPhoneNumber = res?.mobilePhone;
        this.newPhoneNumber = res?.mobilePhoneUnverified;
        this.waitingForConfirmation = res?.waitingForPhoneConfirmation;
        this.customerId = res?.id;
      });
  }

  confirmPhoneNumber() {
    this.customerFacade
      .confirmMobilePhoneNumberChange({
        customerId: this.customerId,
        oldPhoneNumber: this.oldPhoneNumber,
      })
      .pipe(catchError((error) => this.submitError(error)))
      .subscribe((data) => this.submitSuccess(data));
  }

  discardPhoneNumber(): void {
    const data: DialogActionData = {
      body: `Discard new phone number ${this.hiddenPhonePipe.transform(
        this.newPhoneNumber
      )}.`,
      title: "Confirm?",
    };
    DialogActionComponent.show(this.dialog, data).subscribe((result) => {
      if (result) {
        this.customerFacade
          .discardMobilePhoneNumberChange(this.customerId)
          .pipe(catchError((error) => this.submitError(error)))
          .subscribe(() => this.customerFacade.redirectToDashboard());
      }
    });
  }

  submitSuccess(data: ConfirmNewPhoneNumberResponse) {
    if (!data?.hasError) {
      this.customerFacade.redirectToDashboard();
    }
  }

  submitError(error: any) {
    this.messageService.error(error);
    return EMPTY;
  }
}
