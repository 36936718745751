import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";
import { of } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService) { }

    async canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        if (this.authService.authenticated) {
            return this.getIsAuthenticated();

        }
        return this.refreshSession();
    }

    private getIsAuthenticated() {
        this.authService.restartIdleTimer();
        const isAuthObs = of(this.authService.authenticated);
        const isParentSsoSessionValid = this.authService.isSsoSessionValid()
            .pipe(
                tap(isSsoValid => {
                    if (!isSsoValid) {
                        this.authService.sessionEndLocal();
                        this.authService.authorize();
                    }
                })
            );

        return isAuthObs.pipe(
            mergeMap(isAuth => isAuth ? isParentSsoSessionValid : of(isAuth))
        ).toPromise();
    }

    private refreshSession(): PromiseLike<boolean> {
        return this.authService.refreshAuthToken().toPromise().then(res => {
            if (!res?.access_token) {
              if (window.location.pathname !== "/mfa") {
                sessionStorage.setItem('navigation.history', window.location.pathname);
              }
              this.authService.authorize();
              return false;
            }

            this.authService.sessionBegin(
                sessionStorage.loginType,
                res?.access_token,
                res?.expires_in,
                res?.refresh_token,
                res?.refresh_token_expires_in,
                sessionStorage.clientId);

            return true;
        }).catch(_ => {
            if (window.location.pathname !== "/mfa") {
                sessionStorage.setItem('navigation.history', window.location.pathname);
              }
              this.authService.authorize();
              return false;
        });
    }
}
