import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  AppPageComponent,
  AppBarComponent,
  AppBarContactComponent,
  AppFooterComponent,
} from "common";
import { LoginDirective } from "./components/login/login.directive";
import { LoginGuard } from "./infrastructure/login.guard";
import { LogoutGuard } from "./infrastructure/logout.guard";
import { LogoutComponent } from "./components/logout/logout.component";
import { CallbackGuard } from "./components/callback/callback.guard";
import { CallbackComponent } from "./components/callback/callback.component";

const routes: Routes = [
  {
    path: "callback",
    canActivate: [CallbackGuard],
    component: CallbackComponent
  },
  {
    path: "sign-in",
    canActivate: [LoginGuard],
    component: LoginDirective,
  },
  {
    path: "sign-out",
    component: AppPageComponent,
    data: {
      appPage: {
        contentWithoutPadding: true,
        contentClass: "simple",
      },
      appBar: {
        logoSize: "logo-s",
        stickyDisabled: true,
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "right",
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: "",
        component: LogoutComponent,
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
    ],
  },
  {
    path: "registerotp",
    component: AppPageComponent,
    canActivate: [LogoutGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
