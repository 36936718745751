<button mat-icon-button
        [matMenuTriggerFor]="actionsMenu"
        class="secondary-text more-actions-menu">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #actionsMenu="matMenu">
  <button mat-menu-item
          (click)="onClickDetails()">
    <mat-icon>description</mat-icon> <span>Details</span>
  </button>
  <button mat-menu-item
          (click)="onClickDraw()"
          *ngIf="isDrawEnabled">
    <div fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="12px">
      <div class="avatar medium letter mat-light-grey-bg">D</div> <span>Draw funds</span>
    </div>
  </button>
  <button mat-menu-item
          (click)="onClickPay()"
          *ngIf="isPayEnabled">
    <div fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="12px">
      <div class="avatar medium letter mat-light-grey-bg">P</div> <span>Pay balance</span>
    </div>
  </button>
</mat-menu>
