import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UppyFile } from '@uppy/core';
import { RequiredDocument } from '../../model/application-form.model';
import { UploadFileDialogComponent } from '../upload-file-dialog/upload-file-dialog.component';
import { UppyFileChangeTypeEnum } from '../upload-file-dialog/upload-file.model';

@Component({
  selector: 'ifm-upload-document-list-item',
  templateUrl: './upload-document-list-item.component.html',
  styleUrls: ['./upload-document-list-item.component.scss'],
})
export class UploadDocumentListItemComponent implements OnChanges {
  @Input() currentDocument: RequiredDocument;
  @Input() parentObjectId: number;
  @Output() documentFilesChanged = new EventEmitter<UppyFile[]>();

  public tempFiles: UppyFile[];

  constructor(private dialog: MatDialog) {
    this.tempFiles = [];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.currentDocument) {
      this.tempFiles = [];
      this.documentFilesChanged.emit(this.tempFiles);
    }
  }

  public openUploadDialog() {
    UploadFileDialogComponent.show(this.dialog, {
      files: this.tempFiles,
      getMetaCallback: () => {
        return {
          category: this.currentDocument.category,
          requirementDocumentId: this.currentDocument.id,
          parentObjectId: this.parentObjectId
        };
      }
    }).subscribe((result) => {
      if (
        result &&
        result.type &&
        result.type != UppyFileChangeTypeEnum.Canceled
      ) {
        this.tempFiles = result.files;
        this.documentFilesChanged.emit(this.tempFiles);
      }
    });
  }
}
