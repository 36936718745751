<h2 class="heading">{{entity?.dba || entity?.name}} Cases</h2>
<div class="card"
     [ngClass]="{'card--pagination': cases?.length > pageSize}">
  <div class="card__header">
    <ifm-cases-widget-balance *ngIf="entity?.msaAgreementInfo?.status === AgreementStatus.Signed"
                              [entity]="entity">
    </ifm-cases-widget-balance>
    <ifm-cases-widget-search *ngIf="cases?.length > 0"
                             [entity]="entity"
                             (filter)="onFilter($event)"
                             class="search">
    </ifm-cases-widget-search>
  </div>
  <ng-container *ngIf="cases?.length > 0 else noCases">
    <ifm-cases-widget-table [cases]="filteredCases"
                            [entity]="entity">
    </ifm-cases-widget-table>
  </ng-container>

</div>
<div class="pagination"
     *ngIf="cases?.length > pageSize">
  <mat-paginator class="pagination__paginator"
                 #paginator
                 [hidePageSize]="true"
                 [pageSize]="pageSize"
                 (page)="onChangePage($event)"
                 [length]="numberOfAllItems"
                 [showFirstLastButtons]="true">
  </mat-paginator>
</div>
<div class="actions"
     *ngIf="cases?.length > 0">
  <div>
    <span matTooltip="Case Financing"
          class="material-symbols-outlined icon">monetization_on</span> Case Financing
    <span matTooltip="Insurance"
          class="material-symbols-outlined icon">shield</span> Insurance
  </div>
  <button mat-button
          class="actions__item"
          (click)="onClickFundCase()">FUND A CASE</button>
</div>


<ng-template #noCases>
  <div class="no-cases">
    <img class="no-cases__img"
         src="../../../../../assets/img/cases/fund-a-case.png"
         alt="Fund a case">
    <p class="no-cases__text">You now have access to Level Case Financing! Get started by funding your first case.</p>
    <button mat-raised-button
            class="no-cases__action"
            (click)="onClickFundCase()">FUND A CASE</button>
  </div>
</ng-template>
