import { Component, Input } from '@angular/core';

@Component({
  selector: 'ifm-litigation-status-icon-chip',
  templateUrl: './litigation-status-icon-chip.component.html',
  styleUrls: ['./litigation-status-icon-chip.component.scss']
})
export class LitigationStatusIconChipComponent {

  constructor() { }

  @Input()
  status = 'open';

  statusLabels = {
    'pending': { color: 'white-background __gray-icon', icon: 'help', label: 'Pending' },
    'approved': { color: 'white-background __green-icon', icon: 'check_circle', label: 'Approved' },
    'frozen': { color: 'white-background __orange-icon', icon: 'pause_circle_filled', label: 'Frozen' },
    'declined': { color: 'white-background __purple-icon', icon: 'warning', label: 'Declined' },
    'payment-required': { color: 'white-background __orange-icon', icon: 'payments', label: 'Payment Due'}
  };
}
