import { Component, Input, OnChanges, ViewChild } from "@angular/core";
import {
  LitigationData,
  LitigationHintData,
} from "../../../litigation/domain/models/litigation.model";
import { ActionsService } from "../../../../core/actions/actions.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { CasesWidgetFacade } from "./cases-widget.facade";
import { FilterCasesQuery } from "./models/cases-widget.model";
import { AgreementStatus, Entity } from "../../../entity/domain/models/entity.model";

@Component({
  selector: "ifm-cases-widget",
  templateUrl: "./cases-widget.component.html",
  styleUrls: ["./cases-widget.component.scss"],
  providers: [CasesWidgetFacade],
})
export class CasesWidgetComponent implements OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() entity: Entity;
  @Input() cases: LitigationData[];
  filteredCases: LitigationData[];

  numberOfAllItems: number;
  pageSize: number = 10;

  readonly AgreementStatus = AgreementStatus;

  constructor(
    private actions: ActionsService,
    private facade: CasesWidgetFacade
  ) {}

  ngOnChanges(): void {
    this.initCases();
  }

  private initCases() {
    this.filter({ resetPaginator: true });
  }

  onFilter(data: LitigationHintData) {
    this.filter({
      litigationId: data?.litigationId,
      resetPaginator: true,
    });
  }

  onChangePage(data: PageEvent) {
    this.filter({
      pageIndex: data?.pageIndex,
      pageSize: data?.pageSize,
    });
  }

  private filter(query?: FilterCasesQuery) {
    const targetQuery: FilterCasesQuery = {
      ...query,
      pageSize: this.pageSize,
    };
    const response = this.facade.filterCases(this.cases, targetQuery);
    this.filteredCases = response.cases;
    this.numberOfAllItems = response.numberOfAllItems;

    if (query.resetPaginator && this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }

  onClickFundCase() {
    this.actions.fundCaseMsaApplication({ entityId: this.entity.id });
  }
}
