import { NgModule } from "@angular/core";
import { SharedModule } from "projects/my/src/app/shared/shared.module";
import { DocumentsWidgetComponent } from "./documents-widget.component";

@NgModule({
  imports: [SharedModule],
  declarations: [DocumentsWidgetComponent],
  exports: [DocumentsWidgetComponent],
})
export class DocumentsWidgetModule {}
