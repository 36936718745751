import { Routes } from "@angular/router";
import {
  AppBarActionsComponent,
  AppBarCloseAction,
  AppBarComponent,
  AppBarTitleComponent,
  AppFooterComponent,
  AppPageComponent
} from "common";
import { AuthGuard } from "../auth/infrastructure/auth.guard";
import { MinimalLeadApplicationFormGuard } from "./component/minimal-lead-wrapper/minimal-lead-application-form.guard";
import { MinimalLeadWrapperComponent } from "./component/minimal-lead-wrapper/minimal-lead-wrapper.component";
import { MsaApplicationWrapperComponent } from "./component/msa-application-wrapper/msa-application-wrapper.component";
import { MsaApplicationFormGuard } from "./component/msa-application-wrapper/msa-application-form.guard";

export const modulePaths = {
  MinimalWebLead: 'lead',
  MsaApplication: 'application'
}

export const applicationFormRoutes: Routes = [
  {
    path: `application-form/${modulePaths.MinimalWebLead}`,
    canActivate: [AuthGuard, MinimalLeadApplicationFormGuard],
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-center",
        contentWithoutPadding: true,
      },
      appBar: {
        stickyDisabled: true,
        closeAction: AppBarCloseAction.MANUAL,
        logoHidden: true,
      },
      appBarTitle: {
        content: "Application",
      },
    },
    children: [
      {
        path: '',
        component: AppBarComponent,
        outlet: 'app-bar',
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarActionsComponent,
          }
        ]
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
      {
        path: '',
        component: MinimalLeadWrapperComponent
      }
    ],
  },
  {
    path: `application-form/${modulePaths.MsaApplication}`,
    canActivate: [AuthGuard, MsaApplicationFormGuard],
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-center",
        contentWithoutPadding: true,
      },
      appBar: {
        stickyDisabled: true,
        closeAction: AppBarCloseAction.MANUAL,
        logoHidden: true,
      },
      appBarTitle: {
        content: "Application",
      },
    },
    children: [
      {
        path: '',
        component: AppBarComponent,
        outlet: 'app-bar',
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarActionsComponent,
          }
        ]
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
      {
        path: ':appId',
        component: MsaApplicationWrapperComponent,
      },
    ],
  }
];
