import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import {
  ConfigService,
  ApplicationBaseService,
  ApplicationBaseData,
} from "common";
import { AppSettings } from "projects/my/src/app.settings";

@Injectable({
  providedIn: "root",
})
export class ApplicationRemote extends ApplicationBaseService<ApplicationBaseData> {
  constructor(
    http: HttpClient,
    configService: ConfigService,
    private appSettings: AppSettings
  ) {
    super(http, configService);
  }
  
  getAgreementReadyApplications(
  ): Observable<number[]> {
    const url = `${this.appSettings.customers.url}/api/application-agreement/agreements-ready-to-sign`;
    return this.http.get<number[]>(url);
  }
}
