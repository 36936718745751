export enum MsaApplicationStateEnum {
  New = "New",
  NotCreated = "NotCreated",
  Submitted = "Submitted",
}

export interface MsaApplicationStatusResponse {
  status: MsaApplicationStateEnum;
  applicationId?: number;
}
