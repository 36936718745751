import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityState } from "../models/entity-state.model";
import { StateService } from "common";
import { Entity } from "../models/entity.model";

const initialState: EntityState = null;

@Injectable({
  providedIn: "root",
})
export class EntityStateService extends StateService<EntityState> {
  constructor() {
    super(initialState);
  }

  getEntity$(): Observable<Entity> {
    return this.select((state: EntityState) => state?.entity);
  }

  getEntities$(): Observable<Entity[]> {
    return this.select((state: EntityState) => state?.entities);
  }

  setEntity(entity: Entity): void {
    const newState = {
      ...this.state,
      entity,
    };
    this.setState(newState);
  }

  setEntities(entities: Entity[]): void {
    const newState = {
      ...this.state,
      entities,
    };
    this.setState(newState);
  }

  isEntityStateLoaded(id: number): boolean {
    return !!this.state?.entity && this.state.entity.id === id;
  }

  isAnyEntityStateLoaded(): boolean {
    return !!this.state?.entity;
  }

  isEntitiesStateLoaded(): boolean {
    return this.state?.entities?.length > 0;
  }

  clearState(): void {
    this.initState(initialState);
  }
}
