import { Directive, OnInit } from "@angular/core";
import { AuthService } from "../../infrastructure/auth.service";

@Directive({
  selector: "[ifmLogin]"
})
export class LoginDirective implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.authorize();
  }
}
