import { Component, Input } from '@angular/core';
import { ActionsService } from 'projects/my/src/app/core/actions/actions.service';

@Component({
  selector: 'ifm-complete-application-banner',
  templateUrl: './complete-application-banner.component.html',
  styleUrls: ['./complete-application-banner.component.scss']
})
export class CompleteApplicationBannerComponent {

  @Input() applicationId: number;

  constructor(private actions: ActionsService) { }

  onClickCompleteApplication() {
    this.actions.msaApplication({applicationId: this.applicationId});
  }
  
}
