import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { MsaApplicationService } from "../../service/msa-application.service";
import { RouteHelper, parseNumber } from "common";
import { MsaApplicationStatusResponse, MsaApplicationStateEnum } from "./msa-application-form.model";

@Injectable({
  providedIn: "root",
})
export class MsaApplicationFormGuard implements CanActivate {
  constructor(
    private router: Router,
    private msaApplicationService: MsaApplicationService
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> {
    const entityIdParam = RouteHelper.getQueryParamValueByName(_route, 'entityId');
    
    if (!entityIdParam) {
      return of(true);
    }

    return this.msaApplicationService
      .getMsaApplicationStatusResponse(parseNumber(entityIdParam))
      .pipe(
        map((result: MsaApplicationStatusResponse) => {
          switch (result.status) {
            case MsaApplicationStateEnum.New:
            case MsaApplicationStateEnum.NotCreated: {
              return true;
            }
            default: {
              this.router.navigate(["home"]);
              return false;
            }
          }
        })
      );
  }
}
