import { Component, Input, OnChanges } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import {
  LitigationData,
  LitigationStatus,
  LitigationStatusLabel,
} from "projects/my/src/app/features/litigation/domain/models/litigation.model";
import { ActionsService } from "../../../../../../core/actions/actions.service";
import { CustomerFacade } from "projects/my/src/app/features/customer/domain/+state/customer.facade";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { take } from "rxjs/operators";
import { AgreementStatus, Entity } from "projects/my/src/app/features/entity/domain/models/entity.model";
import { CaseWidgetTableData, CasesWidgetTableData } from "./cases-widget-table.model";
import { MsaAgreementMessages } from "../../../../const/data/dashboard-data";

@UntilDestroy()
@Component({
  selector: "ifm-cases-widget-table",
  templateUrl: "./cases-widget-table.component.html",
  styleUrls: ["./cases-widget-table.component.scss"],
})
export class CasesWidgetTableComponent implements OnChanges {
  @Input() entity: Entity;
  @Input() cases: LitigationData[];

  data: CasesWidgetTableData;

  dataSource = new MatTableDataSource<LitigationData>();
  displayedColumns: string[] = ["icons", "name", "status", "action", "endIcon"];

  readonly LitigationStatus = LitigationStatus;
  readonly LitigationStatusLabel = LitigationStatusLabel;

  constructor(
    private actions: ActionsService,
    private customerFacade: CustomerFacade
  ) { }

  ngOnChanges(): void {
    this.data = this.adaptCasesWidgetTableData(this.entity, this.cases);
  }

  private adaptCasesWidgetTableData(entity: Entity, cases: LitigationData[]) {
    const adaptedCases: CaseWidgetTableData[] = cases?.map((item: LitigationData) => this.adaptCaseWidgetTableData(entity, item))
    return { entity: this.entity, cases: adaptedCases }
  }

  private adaptCaseWidgetTableData(entity: Entity, item: LitigationData) {
    return {
      caseData: item,
      metaData: {
        canGoToDetails: (item?.hasLcfLoan && entity?.msaAgreementInfo?.status === AgreementStatus.Signed) || (!item?.hasLcfLoan && item?.hasLcfApplication),
        tooltipMessage: this.prepareCaseTooltipMessage(entity, item),
        canFundCase: item.hasLcpPolicy && !item.hasLcfLoan && !item.hasLcfApplication,
        hasStatusIcon: item.status !== LitigationStatus.Open && item.status !== LitigationStatus.Resolved && item.status !== LitigationStatus.Repayment
      }
    }
  }

  private prepareCaseTooltipMessage(entity: Entity, item: LitigationData) {
    if (!item?.hasLcfLoan || entity?.msaAgreementInfo?.status === AgreementStatus.Signed) {
      return null;
    }

    switch (entity?.msaAgreementInfo?.status) {
      case AgreementStatus.PendingCustomer:
        return MsaAgreementMessages.PendingCustomerDescription;
      case AgreementStatus.PendingOtherOwners:
        return MsaAgreementMessages.PendingOtherOwnersDescription;
      default:
        return MsaAgreementMessages.NoneAgreementDescription;
    }
  }

  // TODO: Refactor by moving getCustomerCheckoutState$ to getDashboardData$, pass as input like cases and entity
  onClickRow(data: CaseWidgetTableData) {
    if (!data?.metaData?.canGoToDetails) {
      return;
    }

    this.customerFacade
      .getCustomerCheckoutState$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((kbaState) =>
        this.actions.caseDetails({
          entityId: this.entity.id,
          caseData: data.caseData,
          verifyKba: kbaState?.verifyKba,
        })
      );
  }

  onClickFundCase(data: CaseWidgetTableData) {
    this.actions.fundCaseMsaApplication({
      entityId: this.entity.id,
      caseName: data.caseData.caseName,
      caseNumber: data.caseData.caseNumber,
      litigationId: data.caseData.litigationId
    });
  }
}
