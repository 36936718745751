import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BrandingService, CaseResolutionDialogComponent, CaseResolutionDialogData, CompanyBranding, LoanHelper, MessageService } from "common";
import { switchMap, tap } from "rxjs/operators";
import { MsaApplicationService } from "../../features/application-form/service/msa-application.service";
import { EntityFacade } from "../../features/entity/domain/+state/entity.facade";
import { LitigationFacade } from "../../features/litigation/domain/+state/litigation.facade";
import { NavigationService } from "../navigation/navigation.service";
import {
  CaseDetailsActionData,
  DashboardActionBaseData,
  FundCaseActionData,
  MsaApplicationActionData,
  OtherFinancingActionData,
  OtherFinancingDetailsActionData,
  ResolvedActionData
} from "./actions.model";
import { ActionsService } from "./actions.service";
import { MsaApplicationStatusResponse, MsaApplicationStateEnum } from "../../features/application-form/component/msa-application-wrapper/msa-application-form.model";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";

@UntilDestroy()
@Injectable()
export class HandleActionsService {

  constructor(
    private actionsService: ActionsService,
    private navigationService: NavigationService,
    private messageService: MessageService,
    private litigationFacade: LitigationFacade,
    private entityFacade: EntityFacade,
    private msaService: MsaApplicationService,
    private router: Router,
    private dialog: MatDialog,
    private brandingService: BrandingService
  ) { }

  initActions(): void {
    this.actionsService.fundCase$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onFundCase(data));

    this.actionsService.fundCaseMsaApplication$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onFundCaseMsaApplication(data));

    this.actionsService.msaApplication$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onMsaApplication(data));

    this.actionsService.caseDetails$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onCaseDetails(data));

    this.actionsService.seeAllLoans$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onSeeAllLoans(data));

    this.actionsService.otherFinancingDetails$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onOtherFinancingDetails(data));

    this.actionsService.draw$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onDraw(data));

    this.actionsService.pay$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onPay(data));

    this.actionsService.seeAllDocuments$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onSeeAllDocuments(data));

    this.actionsService.resolved$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onResolved(data));
  }

  private onFundCase(data: FundCaseActionData) {
    this.navigationService.navigateToPortal("/new-case", data);
  }

  private onFundCaseMsaApplication(data: FundCaseActionData) {
    this.msaService.getMsaApplicationStatusResponse(data.entityId).pipe(
      tap((result: MsaApplicationStatusResponse) => {
        if (result.status === MsaApplicationStateEnum.Submitted) {
          this.onFundCase(data);
          return;
        }
        this.router.navigate([`application-form/application/${result.applicationId ?? 0}`], {
          queryParams: data
        });
      })
    ).subscribe();
  }

  private onMsaApplication(data: MsaApplicationActionData) {
    this.router.navigate([`application-form/application/${data.applicationId}`]);
  }

  private onSeeAllLoans(data: DashboardActionBaseData) {
    this.navigationService.navigateToPortalByEntity("/accounts", data.entityId);
  }

  //TODO: Isn't it better simply when there is no kba to hide cases & other financing? and maybe add some new widget like "please verify kba first"?
  private onCaseDetails(data: CaseDetailsActionData) {
    if (data?.verifyKba) {
      this.router.navigate(["account/setup"]);
      return;
    }

    if (LoanHelper.isNPLLoan(data.caseData?.lcfLoan?.status)) {
      const path = `/account/${data?.caseData?.lcfLoan?.loanId}/bank-account`;
      this.navigationService.navigateToPortalByEntity(path, data.entityId);
      return;
    }

    if (data?.caseData?.hasLcfLoan) {
      const path = `/account/${data?.caseData?.lcfLoan?.loanId}/transactions`;
      this.navigationService.navigateToPortalByEntity(path, data.entityId);
      return;
    }

    if (data?.caseData?.hasLcfApplication) {
      const path = `/application/${data?.caseData?.lcfApplicationId}/documents`;
      this.navigationService.navigateToPortalByEntity(path, data.entityId);
      return;
    }

    this.messageService.errorDefault(
      "Can't redirect from caseWidget(dashboard) to old portal - missing data"
    );
  }

  //TODO: Isn't it better simply when there is no kba to hide cases & other financing? and maybe add some new widget like "please verify kba first"?
  private onOtherFinancingDetails(data: OtherFinancingDetailsActionData) {
    if (data?.verifyKba) {
      this.router.navigate(["account/setup"]);
      return;
    }

    let pathFeature = "/transactions";
    if (LoanHelper.isNPLLoan(data.loanStatus)) {
      pathFeature = "/bank-account";
    }

    const path = `/account/${data.loanId}${pathFeature}`;
    this.navigationService.navigateToPortalByEntity(path, data.entityId);
  }

  private onDraw(data: OtherFinancingActionData) {
    this.navigationService.navigateToPortalByEntity(
      `/account/${data.loanId}/transfer/draw`,
      data.entityId
    );
  }

  private onPay(data: OtherFinancingActionData) {
    this.navigationService.navigateToPortalByEntity(
      `/account/${data.loanId}/transfer/payment`,
      data.entityId
    );
  }

  private onSeeAllDocuments(data: DashboardActionBaseData) {
    this.navigationService.navigateToPortalByEntity("/documents", data.entityId);
  }

  private onResolved(data: ResolvedActionData) {
    this.brandingService.getBranding()
      .pipe(switchMap((branding) => this.saveLiquidityEvent(data, branding)))
      .subscribe(() => {
        this.entityFacade.refreshEntities();
        this.messageService.success(
          "Selected case has been successfully resolved",
          "SUCCESS!"
        );
      });
  }

  private saveLiquidityEvent(data: ResolvedActionData, brandingData: CompanyBranding): Observable<void> {
    return this.litigationFacade
      .saveLiquidityEvent(data.caseId, data.loanId, data.resolutionStatus, data.resolutionDate, data.note, data.isConsented)
      .pipe(
        switchMap(() => this.litigationFacade.getCasePaymentDueDetails(data?.loanId)),
        switchMap((data: CaseResolutionDialogData) => CaseResolutionDialogComponent.show(this.dialog,
          {
            ...data,
            trustpilotReviewUrl: brandingData.trustpilotReviewUrl,
            googleReviewUrl: brandingData.googleReviewUrl
          }))
      )
  }
}
