import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomerRemote } from "../../infrastructure/customer.remote";
import { CustomerStateService } from "./customer.state.service";
import { switchMap, tap } from "rxjs/operators";
import {
  ConfirmNewPhoneNumberRequest,
  CustomerCheckoutState,
  CustomerContactData,
  CustomerContactDataDetails,
  CustomerData,
} from "../models/customer.model";
import { CustomerActionsService } from "./customer-actions.service";

@Injectable({
  providedIn: "root",
})
export class CustomerFacade {
  constructor(
    private readonly stateService: CustomerStateService,
    private readonly remote: CustomerRemote,
    private readonly actionsService: CustomerActionsService
  ) {}

  getCurrentCustomer$(): Observable<CustomerData> {
    if (this.stateService.isCustomerStateLoaded()) {
      return this.stateService.getCustomer$();
    }
    return this.refreshCurrentCustomer$();
  }

  private refreshCurrentCustomer$(): Observable<CustomerData> {
    return this.remote.getCurrent().pipe(
      tap((customer: CustomerData) => this.stateService.setCustomer(customer)),
      switchMap(() => this.stateService.getCustomer$())
    );
  }

  getCustomerCheckoutState$(): Observable<CustomerCheckoutState> {
    if (this.stateService.isCustomerCheckoutStateLoaded()) {
      return this.stateService.getCustomerCheckoutState$();
    }

    return this.refreshCustomerCheckoutState$();
  }

  private refreshCustomerCheckoutState$(): Observable<CustomerCheckoutState> {
    return this.remote.getCheckoutState().pipe(
      tap((details: CustomerCheckoutState) =>
        this.stateService.setCustomerCheckoutState(details)
      ),
      switchMap(() => this.stateService.getCustomerCheckoutState$())
    );
  }

  getCustomerContactDetails$(): Observable<CustomerContactDataDetails> {
    if (this.stateService.isCustomerContactDetailsStateLoaded()) {
      return this.stateService.getCustomerContactDetails$();
    }

    return this.refreshCustomerContactDetails$();
  }

  private refreshCustomerContactDetails$(): Observable<CustomerContactDataDetails> {
    return this.getCurrentCustomer$().pipe(
      switchMap((currentCustomer: CustomerData) =>
        this.remote.getPersonalInformation(currentCustomer.id)
      ),
      tap((details: CustomerContactDataDetails) =>
        this.stateService.setCustomerContactDetails(details)
      ),
      switchMap(() => this.stateService.getCustomerContactDetails$())
    );
  }

  updateCustomerContactDetails(
    customerId: number,
    contactData: CustomerContactData
  ): Observable<void> {
    return this.remote
      .updatePersonalInformation(customerId, contactData)
      .pipe(tap(() => this.refreshCustomerContactDetails$()));
  }

  confirmMobilePhoneNumberChange(
    request: ConfirmNewPhoneNumberRequest
  ): Observable<any> {
    return this.actionsService
      .confirmMobilePhoneNumberChange(request)
      .pipe(tap(() => this.clearCustomerCache()));
  }

  discardMobilePhoneNumberChange(customerId: number): Observable<any> {
    return this.remote
      .discardMobilePhoneNumberChange(customerId)
      .pipe(tap(() => this.clearCustomerCache()));
  }

  redirectToDashboard(): void {
    return this.actionsService.redirectToDashboard();
  }

  clearCustomerCache(): void {
    return this.stateService.clearState();
  }

  getPendingMsaApplication$(): Observable<number> {
    return this.getCurrentCustomer$()
      .pipe(
        switchMap((customer: CustomerData) => 
          this.remote.getPendingMsaApplication(customer.id)
        )
      );
  }
}
