<div class="documents">
  <div class="documents documents__header">
    <span>Speed up the application review by providing additional documents.</span>
    <span>Upload copies in PDF, JPEG, or PNG formats.</span>

    <span class="optionalDocumentsInfo" *ngIf="totalRequiredDocumentsCount === 0">*You can skip this step by pressing SUBMIT and provide documents later, however, submitting your documents now will speed up the process of your application.</span>
  </div>

  <div *ngFor="let document of documents; let i = index">
    <ifm-upload-document-list-item
      [currentDocument]="document"
      [parentObjectId]="parentObjectId"
      (documentFilesChanged)="onDocumentFilesChanged(document, $event)">
    </ifm-upload-document-list-item>
    <hr *ngIf="documents?.length - 1 > i" class="line" />
  </div>
</div>