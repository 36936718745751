import { NgModule } from "@angular/core";
import { SharedModule } from "projects/my/src/app/shared/shared.module";
import { DashboardVendorOffersComponent } from "./dashboard-vendor-offers.component";
import { DashboardVendorOfferWidgetComponent } from "./components/dashboard-vendor-offer-widget/dashboard-vendor-offer-widget.component";

@NgModule({
  imports: [SharedModule],
  declarations: [DashboardVendorOffersComponent, DashboardVendorOfferWidgetComponent],
  exports: [DashboardVendorOffersComponent],
})
export class DashboardVendorOffersModule {}
