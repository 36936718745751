import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  EntityQueryParams,
  ConfigService,
} from "common";
import { AppSettings } from "projects/my/src/app.settings";
import { Entity, EntityQueryResult } from "../domain/models/entity.model";

@Injectable({
  providedIn: "root",
})
export class EntityRemote {
  private readonly baseUrl = `${this.settings.entities.url}/api/entity`;

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private configService: ConfigService
  ) {}

  get(id: number): Observable<Entity> {
    const url = `${this.baseUrl}/with-msa-agreement-info/${id}`;
    return this.http.get<Entity>(url, {
      headers: this.configService.environment.cache.shortLived,
    });
  }

  query(params: EntityQueryParams): Observable<EntityQueryResult> {
    return this.http.get<EntityQueryResult>(`${this.baseUrl}/with-msa-agreement-info`, {
      params: <any>params,
    });
  }
}
