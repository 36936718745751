import { EntityExData, QueryResult } from "common";

export interface Entity extends EntityExData {
    msaAgreementInfo?: MsaAgreementInfoData
  }
  
  export interface MsaAgreementInfoData {
    status?: AgreementStatus;
    applicationId?: number;
  }
  
  export enum AgreementStatus {
    None = "none",
    PendingCustomer = "pending-customer",
    PendingOtherOwners = "pending-other-owners",
    Signed = "signed",
  }
  
  export const AgreementStatusLabel = new Map<string, string>([
    [AgreementStatus.None, "None"],
    [AgreementStatus.PendingCustomer, "Pending customer"],
    [AgreementStatus.PendingOtherOwners, "Pending other owners"],
    [AgreementStatus.Signed, "Signed"]
  ]);
  
  export interface EntityQueryResult extends QueryResult<Entity> {}