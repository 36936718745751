import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RedirectionGuard } from "./infrastructure/redirection.guard";
import { RedirectDirective } from "./components/redirect/redirect.directive";


const routes: Routes = [
  {
    path: "redirect",
    canActivate: [RedirectionGuard],
    component: RedirectDirective,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RedirectionRoutingModule {}
