<div [formGroup]="form"
     class="main_form">
  <div class="lawyer-info" formGroupName="lawyerInfo">
    <span class="lawyer-info__hint">Speed up the application review by providing the required information.</span>

    <span class="lawyer-info__header">Lawyer info</span>

    <div class="lawyer-info__line">
      <mat-form-field appearance="outline">
        <mat-label>First name </mat-label>
        <input id="first-name-form-field"
               matInput
               formControlName="firstName"
               [maxlength]="validationData.firstNameMaxLength + 1"
               type="text" />
        <mat-error id="first-name-required-error-msg"
                   *ngIf="form.get('lawyerInfo.firstName').errors | hasControlError:['required']">
          First name is required.
        </mat-error>
        <mat-error id="first-name-max-length-error-msg"
                   *ngIf="form.get('lawyerInfo.firstName').errors | hasControlError:['maxlength']">
          First name is too long.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last name</mat-label>
        <input id="last-name-form-field"
               matInput
               formControlName="lastName"
               [maxlength]="validationData.lastNameMaxLength + 1"
               type="text" />
        <mat-error id="last-name-required-error-msg"
                   *ngIf="form.get('lawyerInfo.lastName').errors | hasControlError:['required']">
          Last name is required.
        </mat-error>
        <mat-error id="last-name-max-length-error-msg"
                   *ngIf="form.get('lawyerInfo.lastName').errors | hasControlError:['maxlength']">
          Last name is too long.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="lawyer-info__line">
      <mat-form-field appearance="outline">
        <mat-label>SSN</mat-label>
        <input id="ssn-form-field"
               matInput
               formControlName="ssn"
               type="text"
               mask="000-00-0000"
               inputmode="numeric" />
        <mat-hint class="lawyer-info__line__bold">Applying will not affect your credit score.</mat-hint>
        <mat-error id="ssn-required-error-msg"
                   *ngIf="form.get('lawyerInfo.ssn').errors | hasControlError:['required']">
          SSN is required.
        </mat-error>
        <mat-error id="ssn-incorrect-error-msg"
                   *ngIf="form.get('lawyerInfo.ssn').errors | hasControlError:['required']:conditionOperator.Exclude">
          Incorrect SSN.
        </mat-error>
      </mat-form-field>

      <ifc-datepicker id="date-form-field"
                      class="lawyer-info__line"
                      [fc]="form?.controls?.lawyerInfo?.controls?.dateOfBirth"
                      [min]="minDate"
                      [max]="maxDate"
                      label="Date of birth"></ifc-datepicker>
    </div>
    <div class="lawyer-info__line end-padding">
      <mat-form-field appearance="outline">
        <mat-label>Year of Bar admission</mat-label>
        <mat-select id="year-form-field"
                    matInput
                    formControlName="yearOfBarAdmission">
          <mat-option *ngFor="let yearOpt of yearArray"
                      [value]="yearOpt">
            {{ yearOpt }}
          </mat-option>
        </mat-select>
        <mat-error id="year-required-error-msg"
                   *ngIf="form.get('lawyerInfo.yearOfBarAdmission').errors | hasControlError:['required']">
          Year of Bar admission is required.
        </mat-error>
      </mat-form-field>
    </div>

    <span class="lawyer-info__sub-header">Home address</span>
    <ifm-address-form [form]="form?.controls?.lawyerInfo?.controls?.address"></ifm-address-form>
  </div>

  <div class="law-firm-info"
       formGroupName="entityInfo">
    <span class="law-firm-info__header">Law Firm Info</span>

    <div class="law-firm-info__line law-firm-info__full-line">
      <mat-form-field appearance="outline">
        <mat-label>Time in business</mat-label>
        <mat-select id="time-form-field"
                    matInput
                    formControlName="yearInBusiness">
          <mat-option *ngFor="let selectOption of yearInBusinessOptions"
                      [value]="selectOption.value">
            {{ selectOption.name }}
          </mat-option>
        </mat-select>
        <mat-error id="time-required-error-msg"
                   *ngIf="form.get('entityInfo.yearInBusiness').errors | hasControlError:['required']">
          Time in business is required.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="law-firm-info__line">
      <mat-form-field appearance="outline">
        <mat-label>Law firm name</mat-label>
        <input id="company-form-field"
               matInput
               formControlName="company"
               type="text" />
        <mat-error id="company-required-error-msg"
                   *ngIf="form.get('entityInfo.company').errors | hasControlError:['required']">
          Law firm name is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Law firm phone number</mat-label>
        <input id="phone-number-form-field"
               matInput
               formControlName="phoneNumber"
               type="text"
               mask="(000) 000-0000"
               inputmode="numeric" />
        <mat-error id="phone-number-required-error-msg"
                   *ngIf="form.get('entityInfo.phoneNumber').errors | hasControlError:['required']">
          Law firm phone number is required.
        </mat-error>
        <mat-error id="phone-number-incorrect-error-msg"
                   *ngIf="form.get('entityInfo.phoneNumber').errors | hasControlError:['required']:conditionOperator.Exclude">
          Incorrect law firm phone number.
        </mat-error>
      </mat-form-field>
    </div>

    <span class="law-firm-info__sub-header">Law firm address</span>
    <ifm-address-form [form]="form?.controls?.entityInfo?.controls?.address"></ifm-address-form>

    <div class="law-firm-info__line">
      <mat-form-field appearance="outline">
        <mat-label>DBA</mat-label>
        <input id="dba-form-field"
               matInput
               formControlName="dba"
               type="text"
               [maxlength]="validationData.dbaMaxLength + 1" />
        <mat-error id="dba-max-length-error-msg"
                   *ngIf="form.get('entityInfo.dba').errors | hasControlError:['maxlength']">
          Dba is too long.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>EIN</mat-label>
        <input id="ein-form-field"
               matInput
               formControlName="ein"
               type="text"
               mask="00-0000000"
               inputmode="numeric" />
        <mat-error id="ein-required-error-msg"
                   *ngIf="form.get('entityInfo.ein').errors | hasControlError:['required']">
          EIN is required.
        </mat-error>
        <mat-error id="ein-incorrect-error-msg"
                   *ngIf="form.get('entityInfo.ein').errors | hasControlError:['required']:conditionOperator.Exclude">
          Incorrect EIN.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
