import { UppyFile } from "@uppy/core";
import { RequiredDocumentMeta } from "../../model/application-form.model";

export interface ProgressBarDialogComponentConfig {
  filesToUpload: UppyFile[];
  generateEndpoint?: (meta: RequiredDocumentMeta) => string;
}

export enum ProgressBarDialogComponentResultEnum {
  Completed = 'completed',
  Canceled = 'canceled'
}
