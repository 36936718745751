import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AppSettings } from "projects/my/src/app.settings";
import { UserService } from "projects/my/src/app/core/user/user.service";

@Component({
  selector: "ifm-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  registerUrl: string;

  constructor(
    private dialogRef: MatDialog,
    private appSettings: AppSettings,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.registerUrl = this.appSettings.auth.register;
    this.dialogRef.closeAll();
    this.userService.logout();
  }
}
