import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../../shared/shared.module";
import { RedirectDirective } from "./components/redirect/redirect.directive";
import { RedirectionRoutingModule } from "./redirection-routing.module";

@NgModule({
  imports: [SharedModule, CommonModule, RouterModule, RedirectionRoutingModule],
  declarations: [RedirectDirective],
})
export class RedirectionModule {}
