import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { debounceTime, tap } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  DashboardActionData,
  DashboardActionEntityData,
  DashboardActionSelectedValue,
} from "../../models/dashboard-actions.model";
import { ActionSelectFormFieldFacade } from "./action-select-form-field.facade";
import { Observable } from "rxjs";

@UntilDestroy()
@Component({
  selector: "ifm-action-select-form-field",
  templateUrl: "./action-select-form-field.component.html",
  styleUrls: ["./action-select-form-field.component.scss"],
  providers: [ActionSelectFormFieldFacade],
})
export class ActionSelectFormFieldComponent implements OnInit {
  @Input() data: DashboardActionData;
  @Output() selectChange = new EventEmitter<DashboardActionSelectedValue>();

  selectForm = new FormControl<DashboardActionSelectedValue>(
    null,
    Validators.required
  );
  filterForm = new FormControl<string>("");
  filteredEntitiesData$: Observable<DashboardActionEntityData[]>;
  searching: boolean;

  constructor(private facade: ActionSelectFormFieldFacade) {}

  ngOnInit() {
    this.initFilterForm();
    this.initSelectForm();
    this.facade.filter(this.data.entitiesData);
    this.filteredEntitiesData$ = this.facade.filteredEntitiesData$
      .asObservable()
      .pipe(tap(() => (this.searching = false)));
  }

  private initFilterForm() {
    this.filterForm.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => (this.searching = true)),
        debounceTime(600)
      )
      .subscribe((value) => this.facade.filter(this.data.entitiesData, value));
  }

  private initSelectForm() {
    this.selectForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((values) =>
        this.selectChange.emit({ ...values, isValid: this.selectForm.valid })
      );
  }
}
