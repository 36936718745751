import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { CustomerKbaService } from "../../services/customer-kba.service";
import { Observable, combineLatest } from "rxjs";
import { CustomerKbaConversation } from "common";
import { KbaChoiceForm, KbaData, KbaForm, KbaFormData, KbaQuestionForm, KbaQuestionsForm } from "./model/kba-form.model";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { CustomerFacade } from "../../../customer/domain/+state/customer.facade";
import { map, mergeMap, take } from "rxjs/operators";

@UntilDestroy()
@Injectable()
export class KbaFormFacade {

    constructor(
        private kbaService: CustomerKbaService, 
        private customerFacade: CustomerFacade) {
    }

    initData(): Observable<KbaFormData> {
        return combineLatest([this.kbaService.getQuestions(), this.customerFacade.getCurrentCustomer$().pipe(take(1))]).pipe(map(([conversation, customer]) => ({
            customer,
            kbaConversation: conversation
        })));
    }

    initForm(customerKbaConversation: CustomerKbaConversation): FormGroup<KbaForm> {
        return new FormGroup<KbaForm>({
            conversationId: new FormControl(customerKbaConversation.conversationId, {
                validators: [Validators.required]
            }),
            answers: new FormGroup<KbaQuestionsForm>({
                QuestionSetId: new FormControl(customerKbaConversation.questionSet.QuestionSetId, {
                    validators: [Validators.required]
                }),
                Questions: new FormArray<FormGroup<KbaQuestionForm>>(customerKbaConversation.questionSet.Questions.map(question => new FormGroup<KbaQuestionForm>({
                    QuestionId: new FormControl(question.QuestionId),
                    Choices: new FormArray<FormGroup<KbaChoiceForm>>([
                        new FormGroup<KbaChoiceForm>({
                        Choice: new FormControl('', {
                            validators: [Validators.required]
                        })
                    })])
                })))
            })
        })
    }

    save(formData: KbaData) {
        this.customerFacade.clearCustomerCache();

        return this.kbaService
        .answer(formData)
        .pipe(mergeMap(results => {
            return this.customerFacade.getCurrentCustomer$().pipe(take(1), map(customer => {
                return { results, customer };
              }))
        }));
    }
}