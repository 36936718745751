import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppPageComponent, AppBarComponent, AppBarCloseAction, AppBarTitleComponent, AppBarContactComponent } from "common";
import { KbaBeginComponent } from "./features/kba-begin/kba-begin.component";
import { KbaFormComponent } from "./features/kba-form/kba-form.component";
import { KbaResultComponent } from "./features/kba-result/kba-result.component";
import { AuthGuard } from "../auth/infrastructure/auth.guard";

const routes: Routes = [
  {
    path: 'account/setup',
    pathMatch: 'full',
    component: AppPageComponent,
    canActivate: [AuthGuard],
    data: {
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true
      },
      appBarTitle: {
        content: 'What happens next'
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        component: KbaBeginComponent
      }
    ]
  },  {
    path: 'account/setup/verify-identity',
    component: AppPageComponent,
    canActivate: [AuthGuard],
    data: {
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true,
      },
      appBarTitle: {
        content: 'Account setup'
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        component: KbaFormComponent,
      }
    ]
  },
  {
    path: 'account/setup/verify-identity-result',
    component: AppPageComponent,
    canActivate: [AuthGuard],
    data: {
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true,
      },
      appBarTitle: {
        content: 'Account setup'
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        component: KbaResultComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KbaRoutingModule {}
