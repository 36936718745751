<ifc-app-scroll-container class="ifc-app-scroll-container-center">
  <mat-stepper linear [orientation]="(stepperOrientation | async)!" (selectionChange)="onSelectionChange($event)">
    <mat-step [stepControl]="form">
      <ng-template matStepLabel>Additional info</ng-template>
      <ifm-additional-info-application
        [form]="form"
      ></ifm-additional-info-application>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Application documents</ng-template>
      <ifm-required-documents-application 
        [documents]="documents"
        [parentObjectId]="parentObjectId"
        (filesToUploadChanged)="onFilesToUploadChanged($event)"
      ></ifm-required-documents-application>
    </mat-step>
  </mat-stepper>
  <div class="actions">
    <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
  </div>
  <ifc-ssl-info></ifc-ssl-info>
</ifc-app-scroll-container>
