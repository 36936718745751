import { NgModule } from "@angular/core";
import { SharedModule } from "projects/my/src/app/shared/shared.module";
import { CompleteApplicationWidgetComponent } from "./complete-application-widget.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    CompleteApplicationWidgetComponent,
  ],
  exports: [CompleteApplicationWidgetComponent],
})
export class CompleteApplicationWidgetModule {}
