import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Uppy } from "@uppy/core";
import { UploadFileDialogComponent } from "./upload-file-dialog.component";
import {
  UploadFileDialogConfig,
  UploadFileDialogResult,
  UppyFileChangeTypeEnum
} from "./upload-file.model";
import { getUppyInstance } from "../progress-bar-dialog/document-upload-uppy-config";
import { RequiredDocumentMeta } from "../../model/application-form.model";

@Injectable()
export class UploadFileDialogService {
  getMetaCallback: (data: UploadFileDialogConfig) => RequiredDocumentMeta;

  public getUppy(
    data: UploadFileDialogConfig,
    result: UploadFileDialogResult,
    dialog: MatDialogRef<UploadFileDialogComponent>
  ): Uppy {
    if (!result) {
      result = {} as UploadFileDialogResult;
    }
    const uppy = getUppyInstance(data.endpoint, data.accessToken);

    if (data.files) {
      uppy.addFiles(data.files);
    }

    const current = this.setUppyListeners(uppy, data, result, dialog);
    result.type = current.type;
    result.files = current.files;
    return uppy;
  }

  public setMetaCallback(callback: (data: UploadFileDialogConfig) => RequiredDocumentMeta) {
    this.getMetaCallback = callback;
  }

  private setUppyListeners(
    uppy: Uppy,
    data: UploadFileDialogConfig,
    result: UploadFileDialogResult,
    dialog: MatDialogRef<UploadFileDialogComponent, any>
  ): UploadFileDialogResult {
    if (!result) {
      result = {} as UploadFileDialogResult;
    }

    uppy.on("file-added", () => {
      if (data) {
        uppy.getFiles().forEach((uppyFile) => {
          uppy.setFileMeta(uppyFile.id, this.getMeta(data));
        });
      }

      result.type = UppyFileChangeTypeEnum.Added;
      result.files = uppy.getFiles();
    });

    uppy.on("file-removed", () => {
      result.type = UppyFileChangeTypeEnum.Added;
      result.files = uppy.getFiles();
    });

    uppy.on("cancel-all", () => {
      result.type = UppyFileChangeTypeEnum.Canceled
      result.files = undefined;
      dialog.close(result);
    });

    return result;
  }

  private getMeta(data: UploadFileDialogConfig): RequiredDocumentMeta {
    if (this.getMetaCallback) {
      return this.getMetaCallback(data);
    } else {
      console.warn('No endpoint set.')
      return undefined;
    }
  }
}
