<ng-container *ngIf="hasBalanceData">
  <div class="balance-container">
    <div class="balance">
      <span class="balance__amount">${{entity?.msaTerms?.currentAvailableBalance | shortNumber}}</span>
      <span class="balance__text">available of</span>
      <span class="balance__amount">${{entity?.msaTerms?.offerAmount | shortNumber}}</span>
      <span class="balance__text">line limit</span>
      <mat-icon class="balance__icon"
                (click)="showAvailableFundsDialog()">info</mat-icon>
    </div>
    <mat-progress-bar class="progress-bar"
                      mode="determinate"
                      [value]="balanceUtilization">
    </mat-progress-bar>
  </div>
</ng-container>
