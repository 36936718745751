import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService, parseNumber } from 'common';
import { FundCaseActionData } from 'projects/my/src/app/core/actions/actions.model';
import { ActionsService } from 'projects/my/src/app/core/actions/actions.service';
import { zip } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  MinimalApplicationData,
  MinimalApplicationForm,
  RefreshTypeApplicationFromEnum,
  RequiredDocument,
  SaveActionDataApplicationForm,
  SaveAllActionDataApplicationForm,
  SaveFilesActionDataApplicationForm,
  SubmitActionDataApplicationForm,
} from '../../model/application-form.model';
import { MsaApplicationWrapperFacade } from './msa-application-wrapper.facade';

@UntilDestroy()
@Component({
  selector: 'ifm-msa-application',
  templateUrl: './msa-application-wrapper.component.html',
  providers: [ MsaApplicationWrapperFacade ]
})
export class MsaApplicationWrapperComponent implements OnInit {

  public isInProgress: boolean;
  public data: MinimalApplicationData;
  public docs: RequiredDocument[];
  public form: FormGroup<MinimalApplicationForm>;
  public currentApplicationId: number;
  public currentEntityId: number;
  
  private params: FundCaseActionData;
  
  constructor(
    private messageService: MessageService,
    private facade: MsaApplicationWrapperFacade,
    private router: Router,
    private route: ActivatedRoute,
    private actionsService: ActionsService
  ) {
    this.isInProgress = false;
  }
  
  public ngOnInit(): void {
    zip(
      this.route.params,
      this.route.queryParams
    ).pipe(
      switchMap(([params, queryParams]) => {
        this.params = {
          entityId: parseNumber(queryParams.entityId),
          litigationId: parseNumber(queryParams.litigationId),
          caseName: queryParams.caseName,
          caseNumber: queryParams.caseNumber
        };
        
        this.currentEntityId = this.params.entityId;
        this.currentApplicationId = parseNumber(params.appId);
        
        return this.facade.initData(this.currentEntityId, this.currentApplicationId);
      }),
      untilDestroyed(this)
    ).subscribe((result) => {
      this.data = result.data;
      this.docs = result.docs;
    });

    this.form = this.facade.initForm();
  }

  public saveAllHandler(payload: SaveAllActionDataApplicationForm) {
    this.isInProgress = true;
    this.facade
      .save(payload.formData, payload.files, payload.dialog, this.currentApplicationId)
      .subscribe({
        next: (_) => {
          if (payload.navBack) {
            this.navBack()
            return;
          } 
          this.saveSuccess();
        },
        error: (_) => this.subscriptionError(),
      });
  }

  public saveFormHandler(payload: SaveActionDataApplicationForm) {
    this.isInProgress = true;
    
    if (this.currentApplicationId) {
      this.updateApplication(payload, this.currentApplicationId);
      return;
    }
     
    this.createApplication(payload, this.currentEntityId);
  }
  
  private updateApplication(payload: SaveActionDataApplicationForm, applicationId: number) {
    this.facade.saveForm(payload.formData, applicationId).subscribe({
      next: (_) => this.saveSuccess(RefreshTypeApplicationFromEnum.RefreshTypeForm),
      error: (_) => this.subscriptionError(),
    });
  }

  private createApplication(payload: SaveActionDataApplicationForm, entityId: number) {
    this.facade.createFromForm(payload.formData, entityId).pipe(
      tap(appId => this.setCurrentApplicationIdInUrl(appId))
    ).subscribe({
      next: (_) => this.saveSuccess(RefreshTypeApplicationFromEnum.RefreshTypeAll),
      error: (_) => this.subscriptionError(),
    });
  }

  private setCurrentApplicationIdInUrl(applicationId: number) {
    this.currentApplicationId = applicationId;
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.root.children.primary.segments[2].path = `${applicationId}`;
    this.router.navigateByUrl(urlTree);
  }

  public saveFilesHandler(payload: SaveFilesActionDataApplicationForm) {
    this.isInProgress = true;
    this.facade.uploadFiles(payload.files, payload.dialog).subscribe({
      next: (_) => this.saveSuccess(RefreshTypeApplicationFromEnum.RefreshTypeFiles),
      error: (_) => this.subscriptionError(),
    });
  }

  public submitHandler(payload: SubmitActionDataApplicationForm) {
    this.isInProgress = true;
    this.facade
      .uploadFiles(payload.files, payload.dialog)
      .pipe(
        untilDestroyed(this),
        switchMap((_) => this.facade.submit(this.currentApplicationId))
      )
      .subscribe({
        next: (_) => this.submitSuccess(),
        error: (_) => this.subscriptionError(),
      });
  }

  private submitSuccess(): void {
    this.messageService.success("Submitted successfully");
    if (this.params?.entityId) {
      this.actionsService.fundCaseMsaApplication(this.params);
    } else {
      this.router.navigate(["home"]);
    }
  }

  private subscriptionError(): void {
    this.messageService.error("Something went wrong");
    this.isInProgress = false;
  }

  private saveSuccess(refreshType: RefreshTypeApplicationFromEnum = RefreshTypeApplicationFromEnum.RefreshTypeAll): void {
    this.isInProgress = false;
    this.messageService.success("Saved successfully");

    this.facade.initData(this.currentEntityId, this.currentApplicationId).subscribe((result) => {
      if (refreshType === RefreshTypeApplicationFromEnum.RefreshTypeAll || refreshType == RefreshTypeApplicationFromEnum.RefreshTypeForm) {
        this.data = result.data;
      }
      if (refreshType === RefreshTypeApplicationFromEnum.RefreshTypeAll || refreshType == RefreshTypeApplicationFromEnum.RefreshTypeFiles) {
        this.docs = result.docs;
      }
    });
  }
  
  private navBack(): void {
    this.router.navigate(["home"]);
  }
}
