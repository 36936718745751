import { NgModule } from "@angular/core";
import { CasesWidgetComponent } from "./cases-widget.component";
import { CasesWidgetBalanceComponent } from "./components/cases-widget-balance/cases-widget-balance.component";
import { CasesWidgetSearchInputComponent } from "./components/cases-widget-search-input/cases-widget-search-input.component";
import { CasesWidgetSearchComponent } from "./components/cases-widget-search/cases-widget-search.component";
import { CasesWidgetTableComponent } from "./components/cases-widget-table/cases-widget-table.component";
import { SharedModule } from "projects/my/src/app/shared/shared.module";

@NgModule({
  imports: [SharedModule],
  declarations: [
    CasesWidgetComponent,
    CasesWidgetTableComponent,
    CasesWidgetBalanceComponent,
    CasesWidgetSearchComponent,
    CasesWidgetSearchInputComponent,
  ],
  exports: [CasesWidgetComponent],
})
export class CasesWidgetModule {}
