import { Component, OnInit, Input } from "@angular/core";
import { Entity } from "../../features/entity/domain/models/entity.model";
import { BrandingService, CompanyBranding } from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifm-advisor-widget",
  templateUrl: "./advisor-widget.component.html",
  styleUrls: ["./advisor-widget.component.scss"],
})
export class AdvisorWidgetComponent implements OnInit {
  @Input() entity: Entity;
  advisorPhone: string;

  constructor(private readonly brandingService: BrandingService) { }

  ngOnInit() {
    this.initAdvisorPhone();
  }

  private initAdvisorPhone() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((brandingData: CompanyBranding) => {
        this.advisorPhone =
          brandingData?.configuration?.useIndividualAdvisorPhoneNumbers && this.entity?.advisorPhone
            ? this.entity.advisorPhone
            : brandingData?.phone;
      })

  }

  mailLink(email: string) {
    return `mailto:${email}`;
  }

  calendarLink() {
    window.open(
      this.entity?.advisorCalendarLinkUrl ??
      "https://calendly.com/ideafinancial",
      "_blank"
    );
  }
}
