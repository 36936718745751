import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { ObjectHelper } from "common";
import { AppSettings } from "projects/my/src/app.settings";
import { AuthService } from "./auth.service";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class LogoutGuard implements CanActivate {
  constructor(private appSettings: AppSettings, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const queryParamsString = ObjectHelper.getQueryParams(route.queryParams);
    const authReqestQueryParams = this.authService.getHttpRequestParams();
    let queryParams = null;

    if(queryParamsString) {
      queryParams = new HttpParams({ fromString: queryParamsString });
      if(authReqestQueryParams.get('email') === queryParams.get('email')) {
        queryParams = queryParams.delete('email');
      }
    }

    window.location.href = this.appSettings.auth.register + '?' + authReqestQueryParams.toString() + (queryParams ? `&${queryParams.toString()}` : '');
    return true;
  }
}
