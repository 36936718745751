import { Component, OnInit } from "@angular/core";
import {
  RefreshTypeApplicationFromEnum,
  SaveAllActionDataApplicationForm,
  SaveFilesActionDataApplicationForm,
  SaveActionDataApplicationForm,
  SubmitActionDataApplicationForm,
} from "../../model/application-form.model";
import { MinimalLeadWrapperFacade } from "./minimal-lead-wrapper.facade";
import { MessageService } from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { switchMap, tap } from "rxjs/operators";
import {
  MinimalApplicationData,
  MinimalApplicationForm,
  RequiredDocument,
} from "../../model/application-form.model";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";

@UntilDestroy()
@Component({
  selector: "ifm-minimal-lead-wrapper",
  templateUrl: './minimal-lead-wrapper.component.html',
  providers: [ MinimalLeadWrapperFacade ]
})
export class MinimalLeadWrapperComponent implements OnInit {
  public isInProgress: boolean;
  public data: MinimalApplicationData;
  public docs: RequiredDocument[];
  public form: FormGroup<MinimalApplicationForm>;
  public currentId: number;

  constructor(
    private messageService: MessageService,
    private facade: MinimalLeadWrapperFacade,
    private router: Router
  ) {
    this.isInProgress = false;
    sessionStorage.setItem("applicationFormViewed", JSON.stringify(true));
  }

  public ngOnInit(): void {
    this.facade
      .getCurrentLeadId()
      .pipe(
        untilDestroyed(this),
        tap(leadId => this.currentId = leadId),
        switchMap(leadId => this.facade.initData(leadId))
      )
      .subscribe((result) => {
        this.data = result.data;
        this.docs = result.docs;
      });

    this.form =  this.facade.initForm();
  }

  public saveAllHandler(payload: SaveAllActionDataApplicationForm) {
    this.isInProgress = true;
    this.facade
      .save(payload.formData, payload.files, payload.dialog, this.currentId)
      .subscribe({
        next: (_) => {
          if (payload.navBack) {
            this.navBack()
            return;
          } 
          this.saveSuccess();
        },
        error: (_) => this.subscriptionError(),
      });
  }

  public saveFormHandler(payload: SaveActionDataApplicationForm) {
    this.isInProgress = true;
    this.facade.saveForm(payload.formData, this.currentId).subscribe({
      next: (_) => this.saveSuccess(RefreshTypeApplicationFromEnum.RefreshTypeForm),
      error: (_) => this.subscriptionError(),
    });
  }

  public  saveFilesHandler(payload: SaveFilesActionDataApplicationForm) {
    this.isInProgress = true;
    this.facade.uploadFiles(payload.files, payload.dialog).subscribe({
      next: (_) => this.saveSuccess(RefreshTypeApplicationFromEnum.RefreshTypeFiles),
      error: (_) => this.subscriptionError(),
    });
  }

  public submitHandler(payload: SubmitActionDataApplicationForm) {
    this.isInProgress = true;
    this.facade
      .uploadFiles(payload.files, payload.dialog)
      .pipe(
        untilDestroyed(this),
        switchMap((_) => this.facade.submit(this.currentId))
      )
      .subscribe({
        next: (_) => this.submitSuccess(),
        error: (_) => this.subscriptionError(),
      });
  }

  private submitSuccess(): void {
    this.messageService.success("Submitted successfully");
    this.router.navigate(["/"]);
  }

  private subscriptionError(): void {
    this.messageService.error("Something went wrong");
    this.isInProgress = false;
  }

  private saveSuccess(refreshType: RefreshTypeApplicationFromEnum = RefreshTypeApplicationFromEnum.RefreshTypeAll): void {
    this.isInProgress = false;
    this.messageService.success("Saved successfully");

    this.facade.initData(this.currentId).subscribe((result) => {
      if (refreshType === RefreshTypeApplicationFromEnum.RefreshTypeAll || refreshType == RefreshTypeApplicationFromEnum.RefreshTypeForm) {
        this.data = result.data;
      }
      if (refreshType === RefreshTypeApplicationFromEnum.RefreshTypeAll || refreshType == RefreshTypeApplicationFromEnum.RefreshTypeFiles) {
        this.docs = result.docs;
      }
    });
  }

  private navBack() {
    this.router.navigate(["home"]);
    return;
  }
}
