export interface VendorOfferData {
  id?: number;
  date?: Date;
  subject?: string;
  body?: string;
  extra?: string;
  disclaimer?: string;
  importance?: number;
  icon?: string;
  buttons?: VendorOfferButton[];
  dismissable?: boolean;
  loanId?: number;
  isEntityMessage?: boolean;
}

export interface VendorOfferButton {
  label: string;
  link: string;
  appearance: ButtonAppearance;
}

export enum ButtonAppearance {
  normal = 'normal',
  emphasized = 'emphasized'
}

