import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { EntityRemote } from "../../infrastructure/entity.remote";
import { CustomerFacade } from "../../../customer/domain/+state/customer.facade";
import { EntityStateService } from "./entity.state.service";
import { Entity, EntityQueryResult } from "../models/entity.model";

@Injectable({
  providedIn: "root",
})
export class EntityFacade {
  constructor(
    private readonly stateService: EntityStateService,
    private readonly remote: EntityRemote,
    private readonly customerFacade: CustomerFacade
  ) {}

  getEntity$(id: number): Observable<Entity> {
    if (!id && this.stateService.isAnyEntityStateLoaded()) {
      return this.stateService.getEntity$();
    }
    if (!!id && this.stateService.isEntityStateLoaded(id)) {
      return this.stateService.getEntity$();
    }

    return this.refreshEntity$(id);
  }

  getEntities$(): Observable<Entity[]> {
    if (this.stateService.isEntitiesStateLoaded()) {
      return this.stateService.getEntities$();
    }

    return this.updateEntities().pipe(
      switchMap(() => this.stateService.getEntities$())
    );
  }

  private updateEntities(): Observable<void> {
    return this.customerFacade.getCurrentCustomer$().pipe(
      take(1),
      switchMap((customer) => this.remote.query({ customerId: customer.id })),
      map((results: EntityQueryResult) => {
        this.stateService.setEntities(results?.values);
      })
    );
  }

  refreshEntities(): void {
    this.updateEntities().subscribe();
  }

  private refreshEntity$(id: number): Observable<Entity> {
    if (!id) {
      return this.refreshFirstEntityFromList$();
    }

    return this.remote.get(id).pipe(
      catchError(() => of(null)),
      switchMap((entity: Entity) => {
        if (entity) {
          this.stateService.setEntity(entity);
          return this.stateService.getEntity$();
        }
        return this.refreshFirstEntityFromList$();
      })
    );
  }

  private refreshFirstEntityFromList$(): Observable<Entity> {
    return this.getEntities$().pipe(
      switchMap((entities: Entity[]) => {
        const firstEntity = entities?.[0];
        this.stateService.setEntity(firstEntity);
        return this.stateService.getEntity$();
      })
    );
  }

  clearEntityCache(): void {
    return this.stateService.clearState();
  }
}
