import { NgModule } from "@angular/core";
import { SharedModule } from "projects/my/src/app/shared/shared.module";
import { KbaBeginComponent } from "./features/kba-begin/kba-begin.component";
import { KbaRoutingModule } from "./kba-routing.module";
import { KbaFormComponent } from "./features/kba-form/kba-form.component";
import { KbaResultComponent } from "./features/kba-result/kba-result.component";

@NgModule({
  imports: [SharedModule, KbaRoutingModule],
  declarations: [KbaBeginComponent, KbaFormComponent, KbaResultComponent],
  exports: [KbaBeginComponent],
})
export class KbaModule {}
