import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Params } from "@angular/router";
import { NavigationService } from "../../../core/navigation/navigation.service";

@Injectable({
    providedIn: 'root',
})
export class RedirectionGuard implements CanActivate {
    constructor(private router: Router, private navigationService: NavigationService) { }

    async canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        const redirectTo = _next.queryParams.to;
        if(!redirectTo) {
            this.router.navigate(['']);
        }

        var urlParts = redirectTo.split('?');
        var searchParams = urlParts[1];
       
        const queryParams: Params = {};
        if(searchParams)
        {
            const paramsArray = searchParams.split('&');
            for (const param of paramsArray) {
                const [key, value] = param.split('=');
                queryParams[key] = decodeURIComponent(value);
            }
        }
        this.navigationService.navigateToPortal(urlParts[0], queryParams)
        return false;
    }
}
