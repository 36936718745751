import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { UserMenuComponent } from "./user-menu/user-menu.component";
import { AdvisorWidgetComponent } from "./advisor-widget/advisor-widget.component";
import { LitigationStatusIconChipComponent } from "./litigation-status-icon-chip/litigation-status-icon-chip.component";
import { FedDateAdapter } from "projects/common/src/lib/ui/datepicker/date-adapter.component";
import { DateAdapter } from "@angular/material/core";

@NgModule({
  imports: [CommonModule],
  declarations: [
    UserMenuComponent,
    AdvisorWidgetComponent,
    LitigationStatusIconChipComponent
  ],
  exports: [
    CommonModule,
    AdvisorWidgetComponent,
    LitigationStatusIconChipComponent
  ],
  providers: [
      {
          provide: DateAdapter,
          useClass: FedDateAdapter
      }
  ]
})
export class SharedModule {}
