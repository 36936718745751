import { NgModule } from "@angular/core";
import { CoreModule as CoreCommonModule } from "common";
import { NavigationService } from "./navigation/navigation.service";
import { ActionsService } from "./actions/actions.service";
import { HandleActionsService } from "./actions/handle-actions.service";

@NgModule({
  imports: [CoreCommonModule],
  providers: [
    NavigationService,
    ActionsService,
    HandleActionsService
  ],
})
export class CoreModule {}
