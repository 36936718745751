import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { MinimalApplicationData, RequiredDocument } from "../model/application-form.model";
import { AppSettings } from "projects/my/src/app.settings";
import { ConfigService } from "common";

@Injectable()
export class MinimalLeadService {
  
  private baseUrl;

  constructor(private http: HttpClient, private settings: AppSettings, private configService: ConfigService) {
    this.baseUrl = `${this.settings.leads.url}/api/weblead`;
  }

  public getNotSubmitted(): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/not-submitted`, {
      headers: this.configService.environment.cache.noCache,
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400 || err.status === 404) {
          return of(0);
        }
      })
    );
  }

  public get(id: number): Observable<MinimalApplicationData> {
    return this.http.get<MinimalApplicationData>(`${this.baseUrl}/${id}`);
  }

  public getDocuments(id: number): Observable<RequiredDocument[]> {
    return this.http.get<RequiredDocument[]>(`${this.baseUrl}/${id}/files/required-documents`);
  }

  public save(req: MinimalApplicationData, id: number): Observable<string> {
    return this.http.patch<string>(`${this.baseUrl}/${id}`, req);
  }

  public submit(id: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/${id}/submit`, {});
  }
}
