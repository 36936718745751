import { Component, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AvailableFundsExplanationDialogComponent } from "common";
import { Entity } from "projects/my/src/app/features/entity/domain/models/entity.model";

@Component({
  selector: "ifm-cases-widget-balance",
  templateUrl: "./cases-widget-balance.component.html",
  styleUrls: ["./cases-widget-balance.component.scss"],
})
export class CasesWidgetBalanceComponent implements OnChanges {
  @Input() entity: Entity;

  balanceUtilization: number;
  hasBalanceData: boolean;

  constructor(private dialog: MatDialog) {}

  ngOnChanges(): void {
    this.initBalanceData(this.entity);
  }

  private initBalanceData(entity: Entity) {
    if (
      (!entity?.msaTerms?.offerAmount && entity?.msaTerms?.offerAmount !== 0) ||
      (!entity?.msaTerms?.currentAvailableBalance &&
        entity?.msaTerms?.currentAvailableBalance !== 0)
    ) {
      this.hasBalanceData = false;
      return;
    }

    this.hasBalanceData = true;
    this.balanceUtilization = Math.round(
      (entity.msaTerms.currentAvailableBalance / entity.msaTerms.offerAmount) *
        100
    );
  }

  showAvailableFundsDialog(): void {
    AvailableFundsExplanationDialogComponent.show(
      this.dialog,
      "Your available funds is the lower amount of either a case's credit limit minus the outstanding balance of that case, or the Master Service Agreement limit minus the aggregate outstanding balances of all your cases.",
      this.entity?.msaTerms?.currentAvailableBalance,
      this.entity?.msaTerms?.offerAmount
    );
  }
}
