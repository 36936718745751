<div class="container">
  <ng-container [ngSwitch]="msaAgreementStatus">
    <ng-container *ngSwitchCase="AgreementStatus.PendingCustomer">
      <ng-container *ngTemplateOutlet="pendingCustomer"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="AgreementStatus.PendingOtherOwners">
      <ng-container *ngTemplateOutlet="pendingOtherOwners"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="default"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #pendingCustomer>
  <div class="card">
    <h2 class="heading">{{MsaAgreementMessages.PendingCustomerTitle}}</h2>
    <span class="text">{{MsaAgreementMessages.PendingCustomerDescription}}</span>
    <div class="actions">
      <button mat-raised-button
              type="submit"
              (click)="reviewMsaAgreement()"
              color="primary">REVIEW MSA AGREEMENT</button>
    </div>
  </div>
</ng-template>

<ng-template #pendingOtherOwners>
  <div class="card">
    <h2 class="heading">{{MsaAgreementMessages.PendingOtherOwnersTitle}}</h2>
    <span class="text">{{MsaAgreementMessages.PendingOtherOwnersDescription}}</span>
  </div>
</ng-template>

<ng-template #default>
  <div class="card">
    <h2 class="heading">{{MsaAgreementMessages.NoneAgreementTitle}}</h2>
    <span class="text">{{MsaAgreementMessages.NoneAgreementDescription}}</span>
  </div>
</ng-template>
