<ifm-complete-application-banner [applicationId]="pendingMsaApplicationId"
                                 *ngIf="pendingMsaApplicationId">
</ifm-complete-application-banner>
<ifc-app-scroll-container>
  <div class="wrapper">
    <ng-container *ngIf="data$ | async as data">
      <header class="header">
        <h1 class="mat-headline">{{greeting}}</h1>
        <ifm-dashboard-actions [data]="data"></ifm-dashboard-actions>
      </header>
      <section class="content">
        <article class="content__main">
          <ng-container *ngIf="data?.existsNotSubmittedApplication">
            <ifm-complete-application-widget></ifm-complete-application-widget>
          </ng-container>
          <ng-container *ngIf="!data?.existsNotSubmittedApplication && data?.entitiesData?.length === 0">
            <ifm-application-completed-widget></ifm-application-completed-widget>
          </ng-container>
          <ng-container *ngFor="let entityData of data?.entitiesData">
            <div class="entity">
              <ng-container *ngIf="entityData.entity?.msaAgreementInfo?.status !== AgreementStatus.Signed">
                <ifm-review-msa-agreement [msaAgreementStatus]="entityData.entity?.msaAgreementInfo?.status"
                                          [applicationId]="entityData.entity?.msaAgreementInfo?.applicationId">
                </ifm-review-msa-agreement>
              </ng-container>
              <div>
                <ifm-cases-widget [entity]="entityData.entity"
                                  [cases]="entityData.cases">
                </ifm-cases-widget>
              </div>
              <div *ngIf="entityData.otherFinancingLoans?.length > 0">
                <ifm-others-financing-widget [entity]="entityData.entity"
                                             [loans]="entityData.otherFinancingLoans">
                </ifm-others-financing-widget>
              </div>
              <div>
                <ifm-documents-widget [entity]="entityData.entity">
                </ifm-documents-widget>
              </div>
            </div>
          </ng-container>
        </article>
        <mat-divider class="content__divider"
                     vertical>
        </mat-divider>
        <article class="content__extra">
          <ng-container *ngIf="vendorOffers?.length > 0">
            <ifm-dashboard-vendor-offers [offers]="vendorOffers"></ifm-dashboard-vendor-offers>
          </ng-container>
          <ifm-advisor-widget class="advisor"
                              [entity]="entity">
          </ifm-advisor-widget>
          <ifc-finance-contact-widget class="finance-contact">
          </ifc-finance-contact-widget>
        </article>
      </section>
    </ng-container>
  </div>
</ifc-app-scroll-container>
