import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerKbaService } from '../../services/customer-kba.service';

@Component({
  selector: 'ifm-kba-result',
  templateUrl: './kba-result.component.html',
  styleUrls: ['./kba-result.component.scss']
})
export class KbaResultComponent implements OnInit {

  constructor(
    private customerCheckoutService: CustomerKbaService,
    private router: Router) { }

  closeEnabled = true;
  message = '';

  close() {
    this.closeEnabled = false;
    this.router.navigate(['sign-out']);
  }

  ngOnInit() {
    if (this.customerCheckoutService.verifyAnswersError) {
      this.message = this.customerCheckoutService.verifyAnswersError.error_description;
    }
  }

}
