import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { LoanData, LoanHelper } from "common";

@Component({
  selector: "ifm-other-financing-widget-menu",
  templateUrl: "./other-financing-widget-menu.component.html",
})
export class OtherFinancingWidgetMenuComponent implements OnChanges {
  @Input() loan: LoanData;

  @Output() details = new EventEmitter();
  @Output() draw = new EventEmitter();
  @Output() pay = new EventEmitter();

  isDrawEnabled: boolean;
  isPayEnabled: boolean;

  ngOnChanges(): void {
    this.isDrawEnabled = LoanHelper.hasDrawAccess(this.loan);
    this.isPayEnabled = LoanHelper.hasPayAccess(this.loan);
  }

  onClickDetails() {
    this.details.emit();
  }

  onClickDraw() {
    this.draw.emit();
  }

  onClickPay() {
    this.pay.emit();
  }
}
