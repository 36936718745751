import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppSettings } from 'projects/my/src/app.settings';
import { CustomerKbaConversation } from 'common';

@Injectable({
  providedIn: 'root'
})
export class CustomerKbaService {

  private readonly baseUrl = `${this.settings.customers.url}/api/checkout`;

  verifyAnswersError: any;

  constructor(private http: HttpClient,
    private settings: AppSettings,
    private dialog: MatDialog,
    private router: Router) { }

  answer(form: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/idology`, form);
  }

  getQuestions(): Observable<CustomerKbaConversation> {
    return this.http.get<CustomerKbaConversation>(`${this.baseUrl}/idology`);
  }
}
