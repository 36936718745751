<div class="progress" *ngIf="progressValue">
  <h2>Just a moment...</h2>
  <p>Sometimes a little more time is needed when handling multiple files.</p>
  <mat-progress-bar class="progress__bar" mode="determinate" [value]="progressValue"></mat-progress-bar>
  <button mat-button type="button" class="progress__cancel" (click)="cancel()">Cancel</button>
</div>
<div class="info" *ngIf="failureFiles.length">
  <h2>Unable to complete</h2>
  <p>We encountered an error while handling the files below. Give it another try. If the problem persist reach out to us at {{phoneNumber}}</p>
  <div>
    <p class="info__file" *ngFor="let file of failureFiles">{{file.name}}</p>
  </div>
  <div class="info__actions">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-button type="button" class="mat-green" (click)="tryAgain()">Try again</button>
  </div>
</div>
