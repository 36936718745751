<form [formGroup]="form"
      class="address-form">

  <div class="address-form__line wide">
    <mat-form-field appearance="outline"
                    class="wide">
      <mat-label>Street address</mat-label>
      <input id="address-line1-form-field"
             #inlineInput
             matInput
             [formControl]="form?.controls?.line1"
             type="text"
             autocomplete="address-line1"
             [maxlength]="validationData.addressLine1MaxLength + 1" />
      <mat-error id="address-line1-required-error-msg"
                 *ngIf="form.get('line1').errors | hasControlError:['required']">
        Street address is required.
      </mat-error>
      <mat-error id="address-line1-min-length-error-msg"
                 *ngIf="form.get('line1').errors | hasControlError:['minlength']">
        Street address is too short.
      </mat-error>
      <mat-error id="address-line1-max-length-error-msg"
                 *ngIf="form.get('line1').errors | hasControlError:['maxlength']">
        Street address is too long.
      </mat-error>
    </mat-form-field>
  </div>

  <div class="address-form__line">
    <mat-form-field appearance="outline">
      <mat-label>Apt. / Ste. / Unit#</mat-label>
      <input id="address-line2-form-field"
             matInput
             [formControl]="form?.controls?.line2"
             type="text"
             autocomplete="address-line2"
             [maxlength]="validationData.addressLine2MaxLength + 1" />
      <mat-error id="address-line2-max-length-error-msg"
                 *ngIf="form.get('line2').errors | hasControlError">
        Apt. / ste. / unit# is too long.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>City</mat-label>
      <input id="city-form-field"
             #cityInput
             matInput
             [formControl]="form?.controls?.city"
             type="text"
             autocomplete="address-level2"
             [maxlength]="validationData.cityMaxLength + 1" />
      <mat-error id="city-required-error-msg"
                 *ngIf="form.get('city').errors | hasControlError:['required']">
        City is required.
      </mat-error>
      <mat-error id="city-min-length-error-msg"
                 *ngIf="form.get('city').errors | hasControlError:['minlength']">
        City is too short.
      </mat-error>
      <mat-error id="city-max-length-error-msg"
                 *ngIf="form.get('city').errors | hasControlError:['maxlength']">
        City is too long.
      </mat-error>
      <mat-error id="city-incorrect-error-msg"
                 *ngIf="form.get('city').errors | hasControlError:['pattern']:conditionOperator.Only">
        Incorrect city.
      </mat-error>
    </mat-form-field>
  </div>

  <div class="address-form__line">
    <mat-form-field appearance="outline">
      <mat-label>State</mat-label>
      <mat-select id="state-form-field"
                  matInput
                  type="text"
                  [formControl]="form?.controls?.state"
                  autocomplete="address-level1">
        <mat-option *ngFor="let state of states"
                    [value]="state.value">{{
          state.label
        }}</mat-option>
      </mat-select>
      <mat-error id="state-required-error-msg"
                 *ngIf="form.get('state').errors | hasControlError:['required']">
        State is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Zip code</mat-label>
      <input id="zip-code-form-field"
             matInput
             [formControl]="form?.controls?.zip"
             type="text"
             autocomplete="postal-code"
             [maxlength]="validationData.zipMaxLength" />
      <mat-error id="zip-required-error-msg"
                 *ngIf="form.get('zip').errors | hasControlError:['required']">
        Zip code is required.
      </mat-error>
      <mat-error id="zip-incorrect-error-msg"
                 *ngIf="form.get('zip').errors | hasControlError:['required']:conditionOperator.Exclude">
        Incorrect zip code.
      </mat-error>
    </mat-form-field>
  </div>
</form>
