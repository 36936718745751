import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { DashboardActionEntityData } from "../../models/dashboard-actions.model";
import { ReplaySubject } from "rxjs";

@UntilDestroy()
@Injectable()
export class ActionSelectFormFieldFacade {
  filteredEntitiesData$ = new ReplaySubject<DashboardActionEntityData[]>(1);

  filter(entitiesData: DashboardActionEntityData[], searchValue?: string) {
    if (!searchValue) {
      this.refreshFilteredEntitiesData(entitiesData);
      return;
    }

    const filteredEntitiesData = this.filterEntitiesData(
      entitiesData,
      searchValue
    );

    this.refreshFilteredEntitiesData(filteredEntitiesData);
  }

  private filterEntitiesData(
    entitiesData: DashboardActionEntityData[],
    searchValue: string
  ) {
    return entitiesData?.reduce((acc, entityData) => {
      if (
        entityData?.entityName
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase())
      ) {
        acc.push(entityData);
        return acc;
      }

      const matchingCases = entityData?.cases?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
      if (!matchingCases || matchingCases.length === 0) {
        return acc;
      }

      const filteredEntityData: DashboardActionEntityData = {
        ...entityData,
        cases: matchingCases,
      };

      acc.push(filteredEntityData);
      return acc;
    }, []);
  }

  private refreshFilteredEntitiesData(data: DashboardActionEntityData[]) {
    this.filteredEntitiesData$.next(data);
  }
}
