<ifc-app-card [asideOrderLtLg]="-1"
              [asideOrderGtMd]="-1"
              [asideHideXS]="true"
              fxLayout.lt-lg="column"
              fxFlexAlign.lt-lg="center center"
              class="ifp-user-sign-out-form">
  <div ngClass.xs="layout margin">
    <p>
      You have successfully logged out of the application. Close your browser to complete the logout process.
    </p>
    <p>
      Click <a routerLink="/sign-in">here</a> to sign back in.
    </p>
    <p>
      Don't have an account? Click <a [href]="registerUrl">here</a> to sign up.
    </p>
  </div>
  <aside ngClass="user-sign-out-form-aside">
    <p class="mat-display-1">
      Logout successful
    </p>
  </aside>
</ifc-app-card>

<ifc-ssl-info fxHide.xs
              fxLayout="row"
              fxLayoutAlign="center strech"></ifc-ssl-info>
