import { DashboardActionConfig } from "../models/dashboard-actions.model";

export const drawFundsConfig: DashboardActionConfig = {
  inputLabel: "Case or loan",
  singleEntitySearchLabel: "Search by case or loan",
  multipleEntitySearchLabel: "Search by entity, case or loan"
}

export const resolvedConfig: DashboardActionConfig = {
  inputLabel: "Case",
  singleEntitySearchLabel: "Search by case",
  multipleEntitySearchLabel: "Search by entity or case"
}
