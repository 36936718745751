import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AddressForm } from '../../model/application-form.model';
import { FormGroup } from '@angular/forms';
import { FormErrorsConditionOperator } from 'common';
import { AppFormValidationData } from "../application-form/application-form.data";
import { states } from 'common'

declare var google: any;

@Component({
  selector: 'ifm-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {
  @ViewChild('inlineInput', { read: ElementRef, static: true }) inlineInput: ElementRef;
  @ViewChild('cityInput', { read: ElementRef, static: true }) cityInput: ElementRef;
  @Input() form: FormGroup<AddressForm>;

  public states = states;
  public conditionOperator = FormErrorsConditionOperator;
  public validationData = AppFormValidationData;

  private googleAutocompleteStreet: any;
  private googleAutocompleteCity: any;

  private googleAutocompleteOptions: any = {
    types: [],
    componentRestrictions: { country: "us" }
  };
  private googleAutocompleteOptionsCity: any = {
    types: ['(cities)'],
    componentRestrictions: { country: "us" }
  }
  private addressTypeComponents: {[key:string]: string} = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name'
  };

  constructor(private zone: NgZone) { }

  ngOnInit() {
    try {
      this.googleAutocompleteStreet = new google.maps.places.Autocomplete(this.inlineInput.nativeElement, this.googleAutocompleteOptions);
      this.googleAutocompleteStreet.addListener("place_changed", () => this.googleAutocompletePlaceChange(this.googleAutocompleteStreet));

      this.googleAutocompleteCity = new google.maps.places.Autocomplete(this.cityInput.nativeElement, this.googleAutocompleteOptionsCity);
      this.googleAutocompleteCity.addListener("place_changed", () => this.googleAutocompletePlaceChange(this.googleAutocompleteCity));
    } catch {}
  }

  private googleAutocompletePlaceChange(source) {
    this.zone.run(() => {
      const place = source.getPlace();

      this.form?.controls?.line1.setValue(undefined);
      this.form?.controls?.city.setValue(undefined);
      this.form?.controls?.state.setValue(undefined);
      this.form?.controls?.zip.setValue(undefined);
      this.form?.controls?.line2.setValue(undefined);

      (place.address_components || []).forEach(component => {
        const addressType = component.types[0];
        if (!this.addressTypeComponents[addressType]) {
          return;
        }
        const value = component[this.addressTypeComponents[addressType]];
        switch (addressType) {
          case 'street_number':
            this.form?.controls?.line1.setValue(value + (this.form?.value?.line1 ? ' ' + this.form?.value?.line1 : ''));
            break;
          case 'route':
            this.form?.controls?.line1.setValue((this.form?.value?.line1 ? this.form?.value?.line1 + ' ' : '') + value);
            break;
          case 'locality':
            this.form?.controls?.city.setValue(value);
            break;
          case 'administrative_area_level_1':
            this.form?.controls?.state.setValue(value);
            break;
          case 'postal_code':
            this.form?.controls?.zip.setValue(value);
            break;
        }
      });
    });
  }
}
