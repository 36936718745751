import { NgModule } from "@angular/core";
import { SharedModule } from "projects/my/src/app/shared/shared.module";
import { ApplicationCompletedWidgetComponent } from "./application-completed-widget.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    ApplicationCompletedWidgetComponent,
  ],
  exports: [ApplicationCompletedWidgetComponent],
})
export class ApplicationCompletedWidgetModule {}
