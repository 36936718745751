<mat-card class="mat-elevation-z0">
  <mat-card-content>
    <div class="circles" fxLayout="row" fxLayoutGap="5px" >
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle" fxLayoutAlign="center center">
        <div class="crossed">
          <svg>
            <line x1="0" y1="100%" x2="100%" y2="0" />
            <line x1="100%" y1="50%" x2="0" y2="50%" />
            <line x1="50%" y1="100%" x2="50%" y2="0" />
            <line x1="0" y1="0" x2="100%" y2="100%" />
          </svg>
        </div>
      </div>
    </div>
  </mat-card-content>

  <div>
    <mat-card-title>Hello {{customer?.firstName}}!</mat-card-title>
  </div>

  <mat-card-content>
    <section class="content-title">
      You are just a few steps away from accessing your Level Case Financing.
      Once you start, avoid closing your browser, hitting the refresh button, or leaving the screen before proceeding to the next step.
      If you're ready to get started, continue below.
    </section>
  </mat-card-content>

  <mat-card-actions align="center">
    <button mat-raised-button class="continue" (click)="continue()" [disabled]="!continueEnabled">Continue</button>
  </mat-card-actions>
</mat-card>
