import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { AppSettings } from "projects/my/src/app.settings";
import { AuthService } from "../../features/auth/infrastructure/auth.service";

@Injectable()
export class NavigationService {
  constructor(private appSettings: AppSettings, private router: Router, private authService: AuthService) {}

  public navigateToPortalByEntity(path: string, entityId: number) {
    const queryParams = { companyId: entityId };
    return this.navigateToPortal(path, queryParams);
  }

  public navigateToPortal(path: string, params?: Params) {
    const portalUrl = this.appSettings.portal.url;
    const myPortalUrl = this.appSettings.my.url;
    const sessionId = this.authService.getSessionId();

    let queryParams = {
      ...params,
      entityId: undefined,
      companyId: params && "entityId" in params ? params.entityId : params?.companyId ?? undefined,
      sessionId: sessionId ?? null,
      backTo: `${myPortalUrl}/dashboard`,
    };

    const pathUrl = this.router
      .createUrlTree([path], {
        queryParams,
      })
      .toString();
    window.location.href = `${portalUrl}${pathUrl}`;
  }
}
