import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import {
  FundCaseActionData,
  CaseDetailsActionData,
  DashboardActionBaseData,
  OtherFinancingDetailsActionData,
  OtherFinancingActionData,
  ResolvedActionData,
  MsaApplicationActionData
} from "./actions.model";

@Injectable()
export class ActionsService {
  private fundCaseSubject: Subject<FundCaseActionData> = new Subject();
  fundCase$: Observable<FundCaseActionData> =
    this.fundCaseSubject.asObservable();
  fundCase = (data: FundCaseActionData) => this.fundCaseSubject.next(data);

  private fundCaseMsaApplicationSubject: Subject<FundCaseActionData> = new Subject();
  fundCaseMsaApplication$: Observable<FundCaseActionData> =
    this.fundCaseMsaApplicationSubject.asObservable();
  fundCaseMsaApplication = (data: FundCaseActionData) => this.fundCaseMsaApplicationSubject.next(data);

  private msaApplicationSubject: Subject<MsaApplicationActionData> = new Subject();
  msaApplication$: Observable<MsaApplicationActionData> =
    this.msaApplicationSubject.asObservable();
  msaApplication = (data: MsaApplicationActionData) => this.msaApplicationSubject.next(data);

  private caseDetailsSubject: Subject<CaseDetailsActionData> = new Subject();
  caseDetails$: Observable<CaseDetailsActionData> =
    this.caseDetailsSubject.asObservable();
  caseDetails = (data: CaseDetailsActionData) =>
    this.caseDetailsSubject.next(data);

  private seeAllLoansSubject: Subject<DashboardActionBaseData> = new Subject();
  seeAllLoans$: Observable<DashboardActionBaseData> =
    this.seeAllLoansSubject.asObservable();
  seeAllLoans = (data: DashboardActionBaseData) =>
    this.seeAllLoansSubject.next(data);

  private otherFinancingDetailsSubject: Subject<OtherFinancingDetailsActionData> =
    new Subject();
  otherFinancingDetails$: Observable<OtherFinancingDetailsActionData> =
    this.otherFinancingDetailsSubject.asObservable();
  otherFinancingDetails = (data: OtherFinancingDetailsActionData) =>
    this.otherFinancingDetailsSubject.next(data);

  private drawSubject: Subject<OtherFinancingActionData> = new Subject();
  draw$: Observable<OtherFinancingActionData> = this.drawSubject.asObservable();
  draw = (data: OtherFinancingActionData) => this.drawSubject.next(data);

  private paySubject: Subject<OtherFinancingActionData> = new Subject();
  pay$: Observable<OtherFinancingActionData> = this.paySubject.asObservable();
  pay = (data: OtherFinancingActionData) => this.paySubject.next(data);

  private seeAllDocumentsSubject: Subject<DashboardActionBaseData> =
    new Subject();
  seeAllDocuments$: Observable<DashboardActionBaseData> =
    this.seeAllDocumentsSubject.asObservable();
  seeAllDocuments = (data: DashboardActionBaseData) =>
    this.seeAllDocumentsSubject.next(data);

  private resolvedSubject: Subject<ResolvedActionData> = new Subject();
  resolved$: Observable<ResolvedActionData> =
    this.resolvedSubject.asObservable();
  resolved = (data: ResolvedActionData) => this.resolvedSubject.next(data);
}
