import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  StepperOrientation,
  StepperSelectionEvent,
} from "@angular/cdk/stepper";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AppBarAction,
  AppBarActionsService,
  AppBarCloseActionService,
  DialogActionComponent,
} from "common";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  ApplicationFormStepEnum,
  ApplicationFormValidity,
  MinimalApplicationData,
  MinimalApplicationForm,
  RefreshTypeApplicationFromEnum,
  RequiredDocument,
  RequiredDocumentFileUploadModel,
  SaveActionDataApplicationForm,
  SaveAllActionDataApplicationForm,
  SaveFilesActionDataApplicationForm,
  SubmitActionDataApplicationForm,
} from "../../model/application-form.model";
import { ApplicationFormFacade } from "./application-form.facade";

@UntilDestroy()
@Component({
  selector: "ifm-application-form",
  templateUrl: "./application-form.component.html",
  styleUrls: ["./application-form.component.scss"],
  providers: [ApplicationFormFacade],
})
export class ApplicationFormComponent implements OnInit, OnChanges {
  @Input() parentObjectId: number;
  @Input() isInProgress: boolean;
  @Input() data: MinimalApplicationData;
  @Input() documents: RequiredDocument[];
  @Input() form: FormGroup<MinimalApplicationForm>;
  @Output() saveFormAction = new EventEmitter<SaveActionDataApplicationForm>();
  @Output() saveFilesAction = new EventEmitter<SaveFilesActionDataApplicationForm>();
  @Output() saveAllAction = new EventEmitter<SaveAllActionDataApplicationForm>();
  @Output() submitAction = new EventEmitter<SubmitActionDataApplicationForm>();
  @ViewChild(MatStepper, { static: true }) stepper: MatStepper;

  public stepperOrientation: Observable<StepperOrientation>;

  private validityRecheck: BehaviorSubject<ApplicationFormValidity>;
  private isSubmitDisabled$: Observable<boolean>;
  private readonly smallViewPort = [Breakpoints.XSmall, Breakpoints.Small];
  private filesToUpload: RequiredDocumentFileUploadModel[];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private facade: ApplicationFormFacade,
    private appBarActionsService: AppBarActionsService,
    private appBarCloseActionService: AppBarCloseActionService,
    private dialog: MatDialog,
    private router: Router
  ) {}


  public ngOnInit(): void {
    this.filesToUpload = [];

    this.validityRecheck = new BehaviorSubject<ApplicationFormValidity>({
      isFileListValid: false,
      isFormValid: false,
    });
    this.isSubmitDisabled$ = this.validityRecheck.asObservable().pipe(
      map((validity) => {
        return !validity.isFormValid || !validity.isFileListValid;
      })
    );

    this.isSubmitDisabled$
      .pipe(
        untilDestroyed(this),
        tap((isSubmitDisabled: boolean) => {
          this.refreshActions(isSubmitDisabled);
        })
      )
      .subscribe();

    this.appBarActionsService.actions = this.facade.getActions(ApplicationFormStepEnum.Form);
    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe((status) => {
      this.validityRecheck.next({
        ...this.validityRecheck.value,
        isFormValid: status === "VALID",
      });
    });

    this.stepperOrientation = this.breakpointObserver
      .observe(this.smallViewPort)
      .pipe(map(({ matches }) => (matches ? "vertical" : "horizontal")));

    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));

    this.appBarCloseActionService.closing$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.form.dirty) {
          this.handleCloseApplicationForm();
        }

        this.router.navigate(["home"]);
      });
  }
  
  private refreshActions(isSubmitDisabled: boolean) {
    const actions = this.appBarActionsService.actions;
    this.setActionDisabled(actions.find((action) => action.id === 'submit'), isSubmitDisabled || this.isInProgress);
    this.setActionDisabled(actions.find((action) => action.id === 'next'), this.isInProgress);
    this.setActionDisabled(actions.find((action) => action.id === 'back'), this.isInProgress);
    this.setActionDisabled(actions.find((action) => action.id === 'saveForm'), this.isInProgress);
    this.setActionDisabled(actions.find((action) => action.id === 'saveFiles'), this.isInProgress);

    this.appBarActionsService.actions = [...actions];
  }

  private setActionDisabled(action: AppBarAction, isDisabled: boolean) {
    if (action) {
      action.disabled = isDisabled;
    }
  }

  private actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  private handleCloseApplicationForm() {
    const data = this.facade.provideDialogData();
    DialogActionComponent.show(this.dialog, data).subscribe((result) => {
      switch (result) {
        case true: {
          this.saveAll(true);
          return;
        }
        case false: {
          this.form.markAsPristine();
          this.form.markAsUntouched();
          this.router.navigate(["home"]);
          return;
        }
        default: {
          return;
        }
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.previousValue !== changes?.data?.currentValue) {
      this.refreshComponentData(
        { data: changes?.data?.currentValue, docs: undefined },
        RefreshTypeApplicationFromEnum.RefreshTypeForm
      );
    }

    if (changes?.docs?.previousValue !== changes?.docs?.currentValue) {
      this.refreshComponentData(
        { data: undefined, docs: changes?.docs?.currentValue },
        RefreshTypeApplicationFromEnum.RefreshTypeFiles
      );
    }

    if (changes.isInProgress) {
      this.validityRecheck?.next({ ...this.validityRecheck.value });
    }
  }
  
  private refreshComponentData(
    result: {
      data: MinimalApplicationData;
      docs: RequiredDocument[];
    },
    type: RefreshTypeApplicationFromEnum = RefreshTypeApplicationFromEnum.RefreshTypeAll
  ) {
    this.data = result.data;

    if (
      type === RefreshTypeApplicationFromEnum.RefreshTypeAll ||
      type === RefreshTypeApplicationFromEnum.RefreshTypeFiles
    ) {
      this.filesToUpload.splice(0, this.filesToUpload.length);
      this.documents = result.docs;
    }

    if (
      type === RefreshTypeApplicationFromEnum.RefreshTypeAll ||
      type === RefreshTypeApplicationFromEnum.RefreshTypeForm
    ) {
      this.form.patchValue(result.data);
      this.form.markAsPristine();
      this.form.markAsUntouched();
    }

    this.validityRecheck.next({
      ...this.validityRecheck.value,
      isFileListValid: this.documents?.every(
        (doc) => doc?.documents?.length >= doc?.requiredDocumentsCount
      ),
    });
  }

  public onSelectionChange(event: StepperSelectionEvent) {
    this.appBarActionsService.actions = this.facade.getActions(
      event.selectedIndex
    );
    this.validityRecheck.next({ ...this.validityRecheck.value });
  }

  public next() {
    this.saveFormAction.emit({
      formData: this.form.getRawValue() as MinimalApplicationData
    });
    this.stepper.next();
  }

  public back() {
    this.stepper.previous();
  }

  public saveAll(navBack: boolean = false) {
    this.saveAllAction.emit({
      formData: this.form.getRawValue() as MinimalApplicationData,
      files: this.filesToUpload,
      dialog: this.dialog,
      navBack,
    });
  }

  public saveFiles() {
    this.saveFilesAction.emit({
      files: this.filesToUpload, 
      dialog: this.dialog
    });
  }

  public saveForm() {
    const formDataLocal = this.form.getRawValue() as MinimalApplicationData
    if (formDataLocal?.lawyerInfo) {
      formDataLocal.lawyerInfo.dateOfBirth = formDataLocal.lawyerInfo.dateOfBirth ?? null
    }
    this.saveFormAction.emit({
      formData: formDataLocal 
    });
  }

  public submit() {
    this.submitAction.emit({
      files: this.filesToUpload, 
      dialog: this.dialog
    });
  }

  public onFilesToUploadChanged(
    filesToUpload: RequiredDocumentFileUploadModel[]
  ) {
    this.filesToUpload = filesToUpload;
  }
  
  @HostListener("window:beforeunload", ["$event"])
  onBeforeUnloadPreventIfEdited(event: BeforeUnloadEvent) {
    if (!this.form.dirty) {
      return;
    }
      
    event.preventDefault();
    event.returnValue = false;
  }
}
