import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LitigationRemote } from "../../infrastructure/litigation.remote";
import {
  LitigationData,
  LitigationHintData,
  LitigationHintParams,
} from "../models/litigation.model";
import { CaseResolutionDialogData, DateHelper, ResolutionStatus } from "common";

@Injectable({
  providedIn: "root",
})
export class LitigationFacade {

  constructor(private readonly remote: LitigationRemote) {}

  getByEntity(entityId: number): Observable<LitigationData[]> {
    return this.remote.getByEntity(entityId);
  }

  getByEntityHints(
    entityId: number,
    query: LitigationHintParams
  ): Observable<LitigationHintData[]> {
    return this.remote.getByEntityHints(entityId, query);
  }

  saveLiquidityEvent(
    litigationId: number,
    loanId: number,
    resolutionStatus: ResolutionStatus,
    resolutionDate: Date,
    note: string,
    isConsented: boolean
  ): Observable<void> {
    return this.remote.saveLiquidityEvent(
      litigationId,
      loanId,
      resolutionStatus,
      DateHelper.getLocalDateAsUTC(resolutionDate),
      note,
      isConsented
    );
  }

  getCasePaymentDueDetails(loanId: number): Observable<CaseResolutionDialogData> {
    return this.remote
      .getCasePaymentDueDetails(loanId);
  }
}
