import { Injectable } from "@angular/core";
import { MinimalLeadService } from "../../service/minimal-lead.service";
import { AppSettings } from "projects/my/src/app.settings";
import {
  ApplicationAndDataModel,
  DataAndDocumentsArray,
  MinimalApplicationData,
  MinimalApplicationForm,
  RequiredDocumentFileUploadModel,
  RequiredDocumentMeta,
} from "../../model/application-form.model";
import { Observable, forkJoin, of, throwError } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { map, switchMap } from "rxjs/operators";
import { ProgressBarDialogComponent } from "../progress-bar-dialog/progress-bar-dialog.component";
import {
  ProgressBarDialogComponentConfig,
  ProgressBarDialogComponentResultEnum,
} from "../progress-bar-dialog/progress-bar.model";
import { FormGroup } from "@angular/forms";
import { getApplicationFormGroup } from "../application-form/application-form.data";
import { states } from "common";

@Injectable()
export class MinimalLeadWrapperFacade {
  constructor(
    private dataService: MinimalLeadService,
    private settings: AppSettings
  ) {
    sessionStorage.setItem("applicationFormViewed", JSON.stringify(true));
  }

  public getCurrentLeadId(): Observable<number>{
    return this.dataService.getNotSubmitted();
  }

  public initData(leadId: number): Observable<ApplicationAndDataModel> {
    return forkJoin([
      this.getWithStateAbbreviation(leadId),
      this.dataService.getDocuments(leadId)
    ]).pipe(
      map(([data, docs]: DataAndDocumentsArray) => ({ data, docs }))
    );
  }

  public initForm(): FormGroup<MinimalApplicationForm> {
    return new FormGroup<MinimalApplicationForm>(getApplicationFormGroup());
  }

  public initDocs(leadId: number) {
    return this.dataService.getDocuments(leadId);
  }

  public save(
    formData: MinimalApplicationData,
    filesToUpload: RequiredDocumentFileUploadModel[],
    dialog: MatDialog,
    leadId: number
  ): Observable<string> {
    let result = this.saveForm(formData, leadId);

    if (filesToUpload?.length) {
      result = result.pipe(
        switchMap((_) => this.uploadFiles(filesToUpload, dialog))
      );
    }

    return result;
  }

  saveForm(formData: MinimalApplicationData, leadId: number): Observable<string> {
    if(formData?.lawyerInfo?.address?.state) {
      formData.lawyerInfo.address.state = this.getFullStateName(formData.lawyerInfo.address.state);
    }
  
    if(formData?.entityInfo?.address?.state) {
      formData.entityInfo.address.state = this.getFullStateName(formData.entityInfo.address.state);
    }

    return this.dataService.save(formData, leadId);
  }

  public uploadFiles(
    filesToUpload: RequiredDocumentFileUploadModel[],
    dialog: MatDialog
  ): Observable<string> {
    if (!filesToUpload.length) {
      return of("");
    }

    const progressBarDialogComponentConfig =
      this.getProgressBarDialogComponentConfig(filesToUpload);
    return ProgressBarDialogComponent.show(
      dialog,
      progressBarDialogComponentConfig
    ).pipe(
      map((result: ProgressBarDialogComponentResultEnum) => {
        if (result === ProgressBarDialogComponentResultEnum.Completed) {
          return "";
        } else {
          throw throwError("Upload failed");
        }
      })
    );
  }

  public submit(leadId: number): Observable<string> {
    return this.dataService.submit(leadId);
  }

  private getProgressBarDialogComponentConfig(
    filesToUploadModel: RequiredDocumentFileUploadModel[]
  ): ProgressBarDialogComponentConfig {
    const filesToUpload = filesToUploadModel.flatMap(f => f.filesToUpload);
    const generateEndpoint = (meta: RequiredDocumentMeta) => {
      return `${this.settings.leads.url}/api/weblead/${meta.parentObjectId}/files/required-document/${meta.requirementDocumentId}/category/${meta.category}`;
    };
    return { filesToUpload , generateEndpoint };
  }

  private getWithStateAbbreviation(id: number) : Observable<MinimalApplicationData> {
    return this.dataService.get(id).pipe(map((application) => {
      if(application?.lawyerInfo?.address?.state) {
        application.lawyerInfo.address.state = this.getStateAbbreviation(application.lawyerInfo.address.state);
      }
      if(application?.entityInfo?.address?.state) {
        application.entityInfo.address.state = this.getStateAbbreviation(application.entityInfo.address.state);
      }
      return application;
    }))
  }

  private getFullStateName(stateAbbreviation: string) : string {
    if(stateAbbreviation) {
      return states.find(s => s.value == stateAbbreviation).label
    }
    return undefined;
  }

  private getStateAbbreviation(fullStateName: string) : string {
    if(fullStateName) {
      return states.find(s => s.label == fullStateName).value
    }
    return undefined;
  }
}
