import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "projects/my/src/app.settings";
import { Observable } from "rxjs";
import { MinimalApplicationData, RequiredDocument } from "../model/application-form.model";
import { MsaApplicationStatusResponse } from "../component/msa-application-wrapper/msa-application-form.model";
import { ConfigService } from "common";

@Injectable()
export class MsaApplicationService {
  
  private baseUrl;

  constructor(private http: HttpClient, private settings: AppSettings, private configService: ConfigService) {
    this.baseUrl = `${this.settings.applications.url}/api/application`;
  }

  public getMsaApplicationStatusResponse(entityId: number): Observable<MsaApplicationStatusResponse> {
    return this.http.get<MsaApplicationStatusResponse>(`${this.baseUrl}/msa/suitable/${entityId}`, {
      headers: this.configService.environment.cache.noCache,
    });
  }

  public getNew(applicationId: number): Observable<MinimalApplicationData> {
    return this.http.get<MinimalApplicationData>(`${this.baseUrl}/msa/${applicationId}/new`);
  }

  public getPrefill(entityId: number): Observable<MinimalApplicationData> {
    return this.http.get<MinimalApplicationData>(`${this.baseUrl}/msa/pre-fill/${entityId}`);
  }

  public getDocuments(applicationId: number): Observable<RequiredDocument[]> {
    return this.http.get<RequiredDocument[]>(`${this.baseUrl}/${applicationId}/required-documents`);
  }

  public create(req: MinimalApplicationData, entityId: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/msa/${entityId}`, req);
  }

  public save(req: MinimalApplicationData, applicationId: number): Observable<string> {
    return this.http.patch<string>(`${this.baseUrl}/msa/${applicationId}`, req);
  }

  public submit(applicationId: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/msa/${applicationId}/submit`, {});
  }
}
