import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
  } from "@angular/router";
  import { Observable } from "rxjs";
  import { map, take } from "rxjs/operators";
  import { Injectable } from "@angular/core";
import { RouteHelper } from "common";
import { KbaNavigationService } from "../../../services/kba-navigation.service";
import { CustomerFacade } from "../../../../customer/domain/+state/customer.facade";
import { CustomerCheckoutState } from "../../../../customer/domain/models/customer.model";

  @Injectable({
    providedIn: "root",
  })
  export class KbaGuard implements CanActivate {
    constructor(
      private router: Router,
      private customerFacade: CustomerFacade,
      private setupBeginNavigationService: KbaNavigationService
    ) { }

    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean> | boolean {

      const applicationIdPath = RouteHelper.getParamValueByName(route, "appId");

      if ((applicationIdPath && JSON.parse(sessionStorage.getItem("applicationAgreementViewed")))) {
        return true;
      }

      if (JSON.parse(sessionStorage.getItem("accountSetupViewed"))) {
        return true;
      }

      return this.customerFacade
        .getCustomerCheckoutState$()
        .pipe(take(1), map((kbaState) => this.validate(kbaState)));
    }

    validate(
      kbaState: CustomerCheckoutState,
    ):  boolean {
      if (kbaState.verifyKba) {
            this.router.navigate([`account/setup`]);
            return false;
      }
      if (kbaState.kbaError) {
        this.setupBeginNavigationService.verifyIdentityResult({ errorDescription: kbaState.kbaError});
        return false;
      }

      return true;
    }
  }
