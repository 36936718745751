import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { Chart, ChartConfiguration } from "chart.js";
import { LoanData, LoanHelper, LoanInfoDto, ProductCode } from "common";
import {
  getBarChartData,
  getBarChartOptions,
} from "./term-financing-bar-chart-data";

@Component({
  selector: "ifm-other-financing-widget-balance",
  templateUrl: "./other-financing-widget-balance.component.html",
  styleUrls: ["./other-financing-widget-balance.component.scss"],
})
export class OtherFinancingWidgetBalanceComponent implements OnChanges {
  @Input() loan: LoanData;

  @Output() draw = new EventEmitter();
  @Output() pay = new EventEmitter();

  balanceUtilization: number;
  isDrawEnabled: boolean;
  isPayEnabled: boolean;

  barChartData: ChartConfiguration<"bar">["data"] = null;
  barChartOptions: ChartConfiguration<"bar">["options"] = null;

  ProductCode = ProductCode;

  ngOnChanges(): void {
    if (this.loan) {
      this.initData(this.loan);
    }
  }

  private initData(loan: LoanData) {
    this.initBalanceUtilization(loan);
    this.isDrawEnabled = LoanHelper.hasDrawAccess(loan);
    this.isPayEnabled = LoanHelper.hasPayAccess(loan);

    if (loan?.productCode === ProductCode.Term) {
      this.initTermChart(loan);
    }
  }

  private initBalanceUtilization(loan: LoanData) {
    this.balanceUtilization = Math.round(
      (loan.loanInfo.availableFunds / loan.loanInfo.creditLimit) * 100
    );
  }

  private initTermChart(loan: LoanData) {
    Chart.defaults.color = "#974142";
    const values = this.calculateTermPrincipalBalances(loan.loanInfo);
    if (!values || values?.length === 0) {
      this.barChartData = null;
      this.barChartOptions = null;
      return;
    }

    this.barChartData = getBarChartData(
      this.calculateTermPrincipalBalances(loan.loanInfo),
      Array(loan.loanInfo?.totalNumberOfPayments).fill("Balance"),
      "#974142"
    );
    this.barChartOptions = getBarChartOptions(false);
  }

  private calculateTermPrincipalBalances(data: LoanInfoDto): number[] {
    const result = [];

    if (
      data?.principalBalance &&
      data?.paymentAmount &&
      !this.loan.noAutoPayments
    ) {
      const totalInterest =
        Math.round(
          (data?.amortizedBalance || data?.creditLimit) *
            data.interestRate *
            100.0
        ) / 100.0;
      const totalNop = data.totalNumberOfPayments;
      const denominator = (totalNop / 2) * (totalNop + 1);
      const termPayment = data.paymentAmount;

      let principalBalance = data?.amortizedBalance || data?.creditLimit;

      for (let idx = 0; idx < totalNop; idx++) {
        const interestPayment =
          Math.round(
            ((totalInterest * (totalNop - idx)) / denominator) * 100.0
          ) / 100.0;
        const principalPayment = termPayment - interestPayment;

        principalBalance -= principalPayment;

        result.push(principalBalance);
      }
    }
    return result;
  }

  onClickDraw() {
    this.draw.emit();
  }

  onClickPay() {
    this.pay.emit();
  }
}
