import { Injectable } from "@angular/core";
import { LitigationData } from "../../../litigation/domain/models/litigation.model";
import {
  FilterCasesQuery,
  FilterCasesResponse,
} from "./models/cases-widget.model";

@Injectable()
export class CasesWidgetFacade {
  filterCases(
    allCases: LitigationData[],
    query?: FilterCasesQuery
  ): FilterCasesResponse {
    let cases = allCases;
    if (!cases?.length) {
      return this.getReponse(cases, allCases.length);
    }

    if (query.litigationId || query.litigationId === 0) {
      cases = cases?.filter((item) => item.litigationId === query.litigationId);
      return this.getReponse(cases, cases.length);
    }

    let pageIndex = 0;
    if (!query.resetPaginator && "pageIndex" in query) {
      pageIndex = query.pageIndex;
    }

    if (
      (pageIndex || pageIndex === 0) &&
      (query.pageSize || query.pageSize === 0)
    ) {
      cases = this.getPageCases(cases, pageIndex, query.pageSize);
    }

    return this.getReponse(cases, allCases.length);
  }

  private getReponse(cases: LitigationData[], numberOfAllItems: number) {
    return { cases, numberOfAllItems };
  }

  private getPageCases(
    filteredCases: LitigationData[],
    pageIndex: number,
    pageSize: number
  ): LitigationData[] {
    const startIndex = pageIndex * pageSize;
    const pageEndIndex = startIndex + pageSize;
    const lastAvailableIndex = filteredCases.length;
    const endIndex =
      pageEndIndex > lastAvailableIndex ? lastAvailableIndex : pageEndIndex;
    return filteredCases?.slice(startIndex, endIndex);
  }
}
