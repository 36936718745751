import { LoanStatus, ResolutionStatus } from "common";

export interface LitigationData {
  caseName: string;
  caseNumber: string;
  hasLcpPolicy: boolean;
  hasLcfLoan: boolean;
  hasLcfApplication: boolean;
  lcfLoan: LitigationLoanData;
  litigationId: number;
  litigationResolutionStatus: ResolutionStatus;
  lcfApplicationId: number;
  lcpApplicationId: number;
  status: LitigationStatus;
}

export interface LitigationLoanData {
  loanId: number;
  drawAllowed: boolean;
  status: LoanStatus;
  fullRepaymentDueDate: Date;
}

export interface LitigationHintData {
  caseName: string;
  caseNumber: string;
  litigationId: number;
}

export interface LitigationHintParams {
  caseNameOrNumber?: string;
}

export enum LitigationStatus {
  Open = "open",
  Pending = "pending",
  Frozen = "frozen",
  Approved = "approved",
  Declined  = "declined",
  Resolved = "resolved",
  Repayment = "repayment",
  PaymentRequired = "payment-required"
}

export const LitigationStatusLabel = new Map<string, string>([
  [LitigationStatus.Open, "Open"],
  [LitigationStatus.Pending, "Pending"],
  [LitigationStatus.Resolved, "Case resolved"],
  [LitigationStatus.Frozen, "Frozen"],
  [LitigationStatus.Declined, "Declined"],
  [LitigationStatus.Approved, "Approved"],
  [LitigationStatus.Repayment, "Repayment"],
  [LitigationStatus.PaymentRequired, "Payment required"]
]);
