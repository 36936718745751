import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppSettings } from "projects/my/src/app.settings";
import { NavigationService } from "../../core/navigation/navigation.service";

@Component({
  selector: "ifm-user-menu",
  templateUrl: "./user-menu.component.html",
})
export class UserMenuComponent {
  constructor(private router: Router, private appSettings: AppSettings, private navigationService: NavigationService) {}

  logout() {
    this.router.navigate(["/sign-out"]);
  }
  changePassword() {
    window.location.href = this.appSettings.auth.changePassword;
  }

  changeUserProfileData() {
    this.navigationService.navigateToPortal("contact-data/personal/change");
  }

  about() {
    this.navigationService.navigateToPortal("about");
  }
}
