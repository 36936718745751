import { NgModule } from "@angular/core";
import { SharedModule } from "projects/my/src/app/shared/shared.module";
import { DashboardActionsComponent } from "./dashboard-actions.component";
import { ActionSelectFormFieldComponent } from "./components/action-select-form-field/action-select-form-field.component";
import { DrawFundsDialogComponent } from "./components/draw-funds-dialog/draw-funds-dialog.component";
import { ResolvedDialogComponent } from "./components/resolved-dialog/resolved-dialog.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    DashboardActionsComponent,
    ActionSelectFormFieldComponent,
    DrawFundsDialogComponent,
    ResolvedDialogComponent,
  ],
  exports: [DashboardActionsComponent],
})
export class DashboardActionsModule {}
