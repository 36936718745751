import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { MinimalLeadService } from "../../features/application-form/service/minimal-lead.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IsLeadCompletedGuard implements CanActivate {
  constructor(private service: MinimalLeadService, private router: Router) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {

    if (JSON.parse(sessionStorage.getItem("applicationFormViewed"))) {
      return of(true);
    }

    return this.service.getNotSubmitted().pipe(
      map((res: number) => {
        if (res) {
            return this.router.parseUrl("/application-form/lead");
        } else {
            return true;
        }
      })
    );
  }
}
