import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { untilDestroyed, UntilDestroy } from "@ngneat/until-destroy";
import { TimeHelper } from "common";
import { CustomerData } from "../customer/domain/models/customer.model";
import { CustomerFacade } from "../customer/domain/+state/customer.facade";
import { Observable } from "rxjs";
import { DashboardFacade } from "./dashboard.facade";
import { DashboardDataService } from "./services/dashboard-data.service";
import { DashboardData } from "./models/dashboard.model";
import { EntityFacade } from "../entity/domain/+state/entity.facade";
import { VendorOfferData } from "../vendor-offer/domain/models/vendor-offer.model";
import { VendorOfferFacade } from "../vendor-offer/domain/+state/vendor-offer.facade";
import { AgreementStatus, Entity } from "../entity/domain/models/entity.model";

@UntilDestroy()
@Component({
  selector: "ifm-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [
    DashboardFacade,
    DashboardDataService,
  ],
})
export class DashboardComponent implements OnInit {
  readonly AgreementStatus = AgreementStatus;

  greeting: string;
  customer: CustomerData;
  vendorOffers: VendorOfferData[];
  data$: Observable<DashboardData>;
  entity: Entity; // TODO[NP] tems - to remove/refactor (advisor need data from entity, should get some more general data)
  pendingMsaApplicationId: number;

  constructor(
    private customerFacade: CustomerFacade,
    private dashboardFacade: DashboardFacade,
    private vendorOfferFacade: VendorOfferFacade,
    private entityFacade: EntityFacade
  ) {}

  ngOnInit() {
    this.initCustomer();
    this.initData();
    this.initVendorOffers();
    this.initEntity();
    this.initMsaApplication();
  }

  private initCustomer() {
    return this.customerFacade
      .getCurrentCustomer$()
      .pipe(untilDestroyed(this))
      .subscribe((customer) => {
        this.customer = customer;
        this.greeting = `${TimeHelper.getGreetingTime(moment())}, ${
          customer?.firstName
        }!`;
      });
  }

  private initData() {
    this.data$ = this.dashboardFacade.getDashboardData$();
    this.dashboardFacade.initActions();
  }

  private initVendorOffers() {
    return this.vendorOfferFacade
      .getVendorOffers()
      .pipe(untilDestroyed(this))
      .subscribe((vendorOffers) => {
        this.vendorOffers = vendorOffers;
      });
  }

  // TODO[NP] tems - to remove/refactor (advisor need data from entity, should get some more general data)
  private initEntity() {
    return this.entityFacade
      .getEntity$(null)
      .pipe(untilDestroyed(this))
      .subscribe((entity) => {
        this.entity = entity;
      });
  }

  private initMsaApplication() {
    return this.customerFacade
    .getPendingMsaApplication$()
    .pipe(untilDestroyed(this))
    .subscribe((applicationId) => {
      this.pendingMsaApplicationId = applicationId;
    });
  }
}
