import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VendorOfferData } from "../models/vendor-offer.model";
import { VendorOfferRemote } from "../../infrastructure/vendor-offer.remote";

@Injectable({
  providedIn: "root",
})
export class VendorOfferFacade {
  constructor(
    private readonly remote: VendorOfferRemote
  ) {}

  getVendorOffers(): Observable<VendorOfferData[]> {
    return this.remote.get();
  }
}
