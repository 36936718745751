import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  constructor(private oauthService: AuthService, private router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.oauthService.authenticated) {
      this.router.navigate(["dashboard"], { queryParams: next.queryParams });
      return false;
    } else {
      this.oauthService.saveNavigationHistory();
      this.oauthService.authorize();
      return false;
    }
  }
}
