import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RequiredDocument, RequiredDocumentFileUploadModel } from '../../model/application-form.model';
import { UppyFile } from '@uppy/core';
import { ApplicationFormFacade } from '../application-form/application-form.facade';

@Component({
  selector: 'ifm-required-documents-application',
  templateUrl: './required-documents-application.component.html',
  styleUrls: ['./required-documents-application.component.scss']
})
export class RequiredDocumentsApplicationComponent implements OnChanges {

  @Input() documents: RequiredDocument[];
  @Input() parentObjectId: number;
  @Output() filesToUploadChanged = new EventEmitter<RequiredDocumentFileUploadModel[]>();

  public totalRequiredDocumentsCount = 0;

  private filesToUpload: RequiredDocumentFileUploadModel[] = [];

  constructor(private facade: ApplicationFormFacade) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.documents?.currentValue) {
      this.documents = changes.documents.currentValue;
      this.recalculateTotalRequiredDocumentsCount();
    }
  }
  
  private recalculateTotalRequiredDocumentsCount() {
    this.totalRequiredDocumentsCount = this.documents?.reduce((a, b) => a + b?.requiredDocumentsCount, 0);
  }

  public onDocumentFilesChanged(minimalLeadDocument: RequiredDocument, newFiles: UppyFile[]) {
    this.filesToUpload = this.facade.attachFilesToUpload(minimalLeadDocument, newFiles, this.filesToUpload);
    this.filesToUploadChanged.emit(this.filesToUpload);
  }
}
