import { NgModule } from "@angular/core";
import { SharedModule } from "projects/my/src/app/shared/shared.module";
import { OthersFinancingWidgetComponent } from "./others-financing-widget.component";
import { OtherFinancingWidgetMenuComponent } from "./components/other-financing-widget-menu/other-financing-widget-menu.component";
import { OtherFinancingWidgetBalanceComponent } from "./components/other-financing-widget-balance/other-financing-widget-balance.component";
import { OtherFinancingWidgetInfoComponent } from "./components/other-financing-widget-info/other-financing-widget-info.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    OthersFinancingWidgetComponent,
    OtherFinancingWidgetMenuComponent,
    OtherFinancingWidgetBalanceComponent,
    OtherFinancingWidgetInfoComponent,
  ],
  exports: [OthersFinancingWidgetComponent],
})
export class OthersFinancingWidgetModule {}
