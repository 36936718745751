import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../../shared/shared.module";
import { LoginDirective } from "./components/login/login.directive";
import { CallbackComponent } from "./components/callback/callback.component";
import { AuthRoutingModule } from "./auth-routing.module";
import { LogoutComponent } from "./components/logout/logout.component";

@NgModule({
  imports: [SharedModule, CommonModule, RouterModule, AuthRoutingModule],
  declarations: [LoginDirective, CallbackComponent, LogoutComponent],
})
export class AuthModule {}
