import { Component, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActionsService } from "../../../../core/actions/actions.service";
import { DashboardData } from "../../models/dashboard.model";
import { DashboardActionsFacade } from "./dashboard-actions.facade";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  DashboardActionEntityData,
  DrawFundsActionData,
} from "./models/dashboard-actions.model";
import { DrawFundsDialogComponent } from "./components/draw-funds-dialog/draw-funds-dialog.component";
import { ResolvedDialogComponent } from "./components/resolved-dialog/resolved-dialog.component";
import { drawFundsConfig, resolvedConfig } from "./data/dashboard-actions-data";
import { ResolvedActionData } from "projects/my/src/app/core/actions/actions.model";

@UntilDestroy()
@Component({
  selector: "ifm-dashboard-actions",
  templateUrl: "./dashboard-actions.component.html",
  styleUrls: ["./dashboard-actions.component.scss"],
  providers: [DashboardActionsFacade],
})
export class DashboardActionsComponent implements OnChanges {
  @Input() data: DashboardData;
  drawFundsEntitiesData: DashboardActionEntityData[];
  resolvedEntitiesData: DashboardActionEntityData[];
  isDrawEnabled: boolean;
  isResolvedEnabled: boolean;

  constructor(
    public dialog: MatDialog,
    private actions: ActionsService,
    private facade: DashboardActionsFacade
  ) {}

  ngOnChanges(): void {
    this.initData();
  }

  private initData() {
    this.drawFundsEntitiesData = this.facade.adaptDrawFundsEntitiesData(
      this.data
    );
    this.resolvedEntitiesData = this.facade.adaptResolvedEntitiesData(
      this.data
    );
    this.isDrawEnabled = this.drawFundsEntitiesData?.length > 0;
    this.isResolvedEnabled = this.resolvedEntitiesData?.length > 0;
  }

  onClickDrawFunds() {
    this.facade
      .showActionDialog(
        DrawFundsDialogComponent,
        this.drawFundsEntitiesData,
        drawFundsConfig
      )
      .pipe(untilDestroyed(this))
      .subscribe((data: DrawFundsActionData) => {
        if (data) {
          this.actions.draw({ entityId: data.entityId, loanId: data.loanId });
        }
      });
  }

  onClickResolved() {
    this.facade
      .showActionDialog(
        ResolvedDialogComponent,
        this.resolvedEntitiesData,
        resolvedConfig
      )
      .pipe(untilDestroyed(this))
      .subscribe((data: ResolvedActionData) => {
        if (data) {
          this.actions.resolved(data);
        }
      });
  }
}
