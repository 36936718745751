import { Component, Input } from "@angular/core";
import { ActionsService } from "../../../../core/actions/actions.service";
import { Entity } from "../../../entity/domain/models/entity.model";

@Component({
  selector: "ifm-documents-widget",
  templateUrl: "./documents-widget.component.html",
  styleUrls: ["./documents-widget.component.scss"],
})
export class DocumentsWidgetComponent {
  @Input() entity: Entity;

  constructor(private actions: ActionsService) {}

  onClickSeeAllDocuments() {
    this.actions.seeAllDocuments({ entityId: this.entity.id });
  }
}
