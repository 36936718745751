import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApplicationRemote } from "../../infrastructure/application.remote";

@Injectable({
  providedIn: "root",
})
export class ApplicationFacade {
  constructor(
    private readonly remote: ApplicationRemote,
  ) {}

  getAgreementReadyApplications(): Observable<number[]> {
    return this.remote.getAgreementReadyApplications();
  }
}
