import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  LitigationData,
  LitigationHintData,
  LitigationHintParams,
} from "../domain/models/litigation.model";
import { AppSettings } from "projects/my/src/app.settings";
import { CaseResolutionDialogData, ResolutionStatus } from "common";

@Injectable({ providedIn: "root" })
export class LitigationRemote {

  constructor(private http: HttpClient, private settings: AppSettings) {}

  getByEntity(entityId: number): Observable<LitigationData[]> {
    const url = `${this.settings.applications.url}/api/litigations/entity/${entityId}`;
    return this.http.get<LitigationData[]>(url);
  }

  getByEntityHints(
    entityId: number,
    query: LitigationHintParams
  ): Observable<LitigationHintData[]> {
    const url = `${this.settings.applications.url}/api/litigations/entity/${entityId}/hints`;
    return this.http.get<LitigationHintData[]>(url, { params: <any>query });
  }

  saveLiquidityEvent(
    litigationId: number,
    loanId: number,
    resolutionStatus: ResolutionStatus,
    resolutionDate: Date,
    note: string,
    isConsented: boolean
  ): Observable<void> {
    return this.http.patch<any>(
      `${this.settings.applications.url}/api/litigations/${litigationId}/loan/${loanId}/customer-resolution`
      , { status: resolutionStatus, date: resolutionDate, notes: note, notesConsent: isConsented }
    );
  }

  getCasePaymentDueDetails(loanId: number): Observable<CaseResolutionDialogData> {
    return this.http.get<CaseResolutionDialogData>(
      `${this.settings.loans.url}/api/account/${loanId}/case-due`
    );
  }
}
