import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomerState } from "../models/customer-state.model";
import { StateService } from "common";
import { CustomerCheckoutState, CustomerContactDataDetails, CustomerData } from "../models/customer.model";

const initialState: CustomerState = null;

@Injectable({
  providedIn: "root",
})
export class CustomerStateService extends StateService<CustomerState> {
  constructor() {
    super(initialState);
  }

  getCustomer$(): Observable<CustomerData> {
    return this.select((state: CustomerState) => state?.customer);
  }

  getCustomerContactDetails$(): Observable<CustomerContactDataDetails> {
    return this.select((state: CustomerState) => state?.customerContactDetails);
  }
  
  getCustomerCheckoutState$(): Observable<CustomerCheckoutState> {
    return this.select((state: CustomerState) => state?.customerCheckoutState);
  }

  setCustomer(customer: CustomerData): void {
    const newState = {
      ...this.state,
      customer,
    };
    this.setState(newState);
  }

  setCustomerContactDetails(
    customerContactDetails: CustomerContactDataDetails
  ): void {
    const newState = {
      ...this.state,
      customerContactDetails,
    };
    this.setState(newState);
  }

  setCustomerCheckoutState(
    customerCheckoutState: CustomerCheckoutState
  ): void {
    const newState = {
      ...this.state,
      customerCheckoutState,
    };
    this.setState(newState);
  }

  isCustomerStateLoaded(): boolean {
    return !!this.state?.customer;
  }

  isCustomerContactDetailsStateLoaded(): boolean {
    return !!this.state?.customerContactDetails;
  }

  isCustomerCheckoutStateLoaded(): boolean {
    return !!this.state?.customerCheckoutState;
  }

  clearState(): void {
    this.initState(initialState);
  }
}
