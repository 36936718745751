import { Component, OnInit } from "@angular/core";
import { KbaNavigationService } from "../../services/kba-navigation.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CustomerData } from "../../../customer/domain/models/customer.model";
import { CustomerFacade } from "../../../customer/domain/+state/customer.facade";

@UntilDestroy()
@Component({
  selector: "ifm-kba-begin",
  templateUrl: "./kba-begin.component.html",
  styleUrls: ["./kba-begin.component.scss"],
})
export class KbaBeginComponent implements OnInit {
  customer: CustomerData;
  continueEnabled = true;

  constructor(
    private customerFacade: CustomerFacade,
    private setupBeginNavigationService: KbaNavigationService
  ) {}

  ngOnInit() {
    this.initCustomer();
    sessionStorage.setItem("accountSetupViewed", JSON.stringify(true));
  }

  continue() {
    this.continueEnabled = false;
    this.setupBeginNavigationService.verifyIdentity();
  }

  private initCustomer() {
    return this.customerFacade
      .getCurrentCustomer$()
      .pipe(untilDestroyed(this))
      .subscribe((customer) => {
        this.customer = customer;
      });
  }
}
