import { Address } from "../address/address.model";

export interface CustomerData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  kbaStatus: KBAVerificationStatus;
  pendingLoans: number[];
  loans: number[];
  referralCode: string;
  referredByCode: string;
  preferredPhone: number;
  mobilePhone: number;
  mobilePhoneUnverified: number;
  waitingForPhoneConfirmation: boolean;
}

export interface CustomerContactData {
  mobilePhone: number;
  homePhone: number;
  preferredPhone: number;
  address: Address;
}

export interface CustomerContactDataDetails extends CustomerContactData {
  id: number;
}

export enum KBAVerificationStatus {
  None = "none",
  Success = "success",
  Failed = "failed",
}

export interface ConfirmNewPhoneNumberRequest {
  customerId: number;
  oldPhoneNumber: number;
  mfaToken?: string;
  mfaCode?: string;
}

export interface ConfirmNewPhoneNumberResponse {
  hasError: boolean;
  errorCode?: string;
}
