<mat-dialog-content>
  <header>
    <h2 class="heading">Draw funds</h2>
  </header>
  <section class="content">
    <ifm-action-select-form-field [data]="data"
                                  (selectChange)="onSelectChange($event)">
    </ifm-action-select-form-field>
  </section>
  <footer class="actions">
    <button mat-button
            [mat-dialog-close]="null">
      CANCEL
    </button>
    <button class="actions__submit"
            mat-button
            [disabled]="!selectedData?.isValid"
            (click)="onSubmit()">
      OK
    </button>
  </footer>
</mat-dialog-content>
