import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CompleteApplicationActionsService {
  constructor(private router: Router) {}

  completeApplication() {
    this.router.navigate(['application-form/lead'])
  }
}
