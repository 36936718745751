import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "projects/my/src/app.settings";
import { map } from "rxjs/operators";
import { AccountQueryParams, LoanQueryResult } from "common";

@Injectable({ providedIn: "root" })
export class LoanRemote {
  constructor(private http: HttpClient, private settings: AppSettings) {}

  query(params: AccountQueryParams): Observable<LoanQueryResult> {
    const url = `${this.settings.loans.url}/api/account/list`;
    return this.http
      .get(url, { params: <any>params })
      .pipe(map<any, LoanQueryResult>((result) => result));
  }

  getAgreementReadyLoans(
    ): Observable<number[]> {
      const url = `${this.settings.loans.url}/api/account/agreement-ready-loans`;
      return this.http.get<number[]>(url);
  }
}
