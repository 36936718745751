import { Injectable } from "@angular/core";
import { AppSettings } from "projects/my/src/app.settings";
import {
  MinimalApplicationData,
  MinimalApplicationForm,
  RequiredDocumentFileUploadModel,
  RequiredDocumentMeta,
  ApplicationAndDataModel,
  DataAndDocumentsArray,
} from "../../model/application-form.model";
import { Observable, forkJoin, of, throwError } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { map, switchMap } from "rxjs/operators";
import { ProgressBarDialogComponent } from "../progress-bar-dialog/progress-bar-dialog.component";
import {
  ProgressBarDialogComponentConfig,
  ProgressBarDialogComponentResultEnum,
} from "../progress-bar-dialog/progress-bar.model";
import { MsaApplicationService } from "../../service/msa-application.service";
import { Router } from '@angular/router';
import { MessageService, parseNumber } from 'common';
import { FormGroup } from "@angular/forms";
import { getApplicationFormGroup } from "../application-form/application-form.data";
import { MsaApplicationStatusResponse, MsaApplicationStateEnum } from "./msa-application-form.model";


@Injectable()
export class MsaApplicationWrapperFacade {
  constructor(
    private dataService: MsaApplicationService,
    private settings: AppSettings,
    private router: Router,
    private messageService: MessageService
  ) {
  }

  public initData(entityId: number, applicationId: number): Observable<ApplicationAndDataModel> {
    const data$: Observable<DataAndDocumentsArray> = entityId ? 
      this.getDataForEntity(entityId, applicationId) : this.getDataForApplication(applicationId);
      
    return data$.pipe(
      map(([data, docs]: DataAndDocumentsArray) => ({ data, docs }))
    );
  }

  public initForm(): FormGroup<MinimalApplicationForm> {
    return new FormGroup<MinimalApplicationForm>(this.getMsaApplicationForm());
  }

  public prefill(entityId: number, applicationId: number): Observable<ApplicationAndDataModel> {
    return this.dataService.getPrefill(entityId).pipe(
      map(x => ({ data: x, docs: [], currentApplicationId: applicationId, currentEntityId: entityId}))
    );
  }

  public save(
    formData: MinimalApplicationData,
    filesToUpload: RequiredDocumentFileUploadModel[],
    dialog: MatDialog,
    applicationId: number
  ): Observable<string> {
    let result = this.saveForm(formData, applicationId);
    if (filesToUpload?.length > 0) {
      result = result.pipe(
        switchMap((_) => this.uploadFiles(filesToUpload, dialog))
      );
    }

    return result;
  }
  
  public saveForm(formData: MinimalApplicationData, applicationId: number): Observable<string> {
    return this.dataService.save(formData, applicationId);
  }

  public createFromForm(formData: MinimalApplicationData, entityId: number): Observable<number> {
    return this.dataService.create(formData, entityId).pipe(
      map(appId => (parseNumber(appId)))
    );
  }

  public uploadFiles(
    filesToUpload: RequiredDocumentFileUploadModel[],
    dialog: MatDialog
  ): Observable<string> {
    if (!filesToUpload?.length) {
      return of("");
    }

    const progressBarDialogComponentConfig =
      this.getProgressBarDialogComponentConfig(filesToUpload);
    return ProgressBarDialogComponent.show(
      dialog,
      progressBarDialogComponentConfig
    ).pipe(
      map((result: ProgressBarDialogComponentResultEnum) => {
        if (result === ProgressBarDialogComponentResultEnum.Completed) {
          return "";
        } else {
          throw throwError("Upload failed");
        }
      })
    );
  }
  
  public submit(applicationId: number): Observable<string> {
    return this.dataService.submit(applicationId);
  }

  private getProgressBarDialogComponentConfig(
    filesToUploadModel: RequiredDocumentFileUploadModel[]
  ): ProgressBarDialogComponentConfig {
    const filesToUpload = filesToUploadModel.flatMap(f => f.filesToUpload);
    const generateEndpoint = (meta: RequiredDocumentMeta) => {
      return `${this.settings.applications.url}/api/application/${meta.parentObjectId}/required-document/${meta.requirementDocumentId}/category/${meta.category}`;
    };
    return { filesToUpload , generateEndpoint };
  }

  private getMsaApplicationForm() {
    const form = getApplicationFormGroup();

    form.lawyerInfo.controls.firstName.disable();
    form.lawyerInfo.controls.lastName.disable();
    form.entityInfo.controls.company.disable();

    return form;
  }
  
  private getDataForEntity(entityId: number, applicationId: number): Observable<DataAndDocumentsArray> {
    return this.dataService.getMsaApplicationStatusResponse(entityId).pipe(
      switchMap((statusResponse: MsaApplicationStatusResponse) => {
        switch (statusResponse.status) {
          case MsaApplicationStateEnum.NotCreated: {
            return forkJoin([
              this.dataService.getPrefill(entityId),
              of([])
            ]);
          }
          case MsaApplicationStateEnum.New: {
            return this.getDataForApplication(applicationId)
          }
          case MsaApplicationStateEnum.Submitted: {
            this.messageService.error("Application already submitted.");
            this.router.navigate(["home"]);
            break;
          }
          default: {
            this.messageService.error("Invalid state cannot continue.");
            this.router.navigate(["error"]);
            throwError('Invalid state');
            break;
          }
        }
      })
    );
  }

  private getDataForApplication(applicationId: number): Observable<DataAndDocumentsArray> {
    return forkJoin([
      this.dataService.getNew(applicationId),
      this.dataService.getDocuments(applicationId),
    ]);
  }
}
