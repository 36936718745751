<div class="document">
    <mat-icon class="mat-green" *ngIf="currentDocument?.documents?.length >= currentDocument?.requiredDocumentsCount; else emptyIcon">check_circle</mat-icon>
    <div class="detail">
      <p class="detail__title">{{currentDocument?.requiredDocument}}</p>
      <p class="detail__description">{{currentDocument?.notes}} <a *ngIf="currentDocument?.fileName" [href]="'assets/docs/'+currentDocument?.fileName" target="_blank">downloaded here.</a></p>
      <div>
        <p class="detail__uploaded" *ngFor="let document of currentDocument?.documents">{{document.filename}}</p>
        <p class="detail__uploadable" *ngFor="let document of tempFiles">{{document.name}}</p>
      </div>
    </div>
    <button mat-button name="Upload" class="document__upload-btn" (click)="openUploadDialog()">Upload</button>
  </div>
  <ng-template #emptyIcon><div class="empty-icon"></div></ng-template>