import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AccountHintData, AutocompleteComponent } from "common";
import { LitigationFacade } from "projects/my/src/app/features/litigation/domain/+state/litigation.facade";
import { EMPTY, Observable } from "rxjs";

@Component({
  selector: "ifm-cases-widget-search-input",
  templateUrl: "./cases-widget-search-input.component.html",
  styleUrls: ["./cases-widget-search-input.component.scss"],
})
export class CasesWidgetSearchInputComponent
  extends AutocompleteComponent
  implements OnInit
{
  @Input() entityId: number;
  @Output() enter = new EventEmitter<boolean>();
  @Output() backAction = new EventEmitter<string>();

  label = "Search";
  leftIcon = "search";

  constructor(private litigationFacade: LitigationFacade) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.optionLabelKey = "caseName";
  }

  optionFilter: (input: string | AccountHintData) => Observable<any[]> = (
    input
  ) => {
    if (!input || typeof input !== "string" || !this.entityId) {
      return EMPTY;
    }

    return this.litigationFacade.getByEntityHints(this.entityId, {
      caseNameOrNumber: input,
    });
  };

  back() {
    this.valueClear();
    this.backAction.emit();
  }

  onFocus() {
    this.leftIcon = "west";
    this.enter.emit(true);
  }

  onBlur() {
    this.leftIcon = "search";
    this.enter.emit(false);
  }
}
