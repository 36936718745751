<ng-container *ngIf="isDrawEnabled || isResolvedEnabled">
  <div class="actions">
    <button *ngIf="isDrawEnabled"
            mat-raised-button
            class="actions__draw"
            (click)="onClickDrawFunds()">
      DRAW FUNDS
    </button>
    <button *ngIf="isResolvedEnabled"
            mat-stroked-button
            class="actions__resolved"
            (click)="onClickResolved()">
      PAYOFF
    </button>
  </div>
</ng-container>
