import { Component } from "@angular/core";

@Component({
  selector: "ifm-application-completed-widget",
  templateUrl: "./application-completed-widget.component.html",
  styleUrls: ["./application-completed-widget.component.scss"],
})
export class ApplicationCompletedWidgetComponent {
  constructor() {}
}
