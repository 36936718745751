import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MinimalApplicationForm } from "../../model/application-form.model";
import { EnumHelper, EnumValue, FormErrorsConditionOperator, TimeInBusinessPeriod, TimeInBusinessPeriodLabel } from "common";
import { AppFormValidationData } from "../application-form/application-form.data";

@Component({
  selector: "ifm-additional-info-application",
  templateUrl: "./additional-info-application.component.html",
  styleUrls: ["./additional-info-application.component.scss"],
})
export class AdditionalInfoApplicationComponent {
  @Input() form: FormGroup<MinimalApplicationForm>;

  public conditionOperator = FormErrorsConditionOperator;
  public validationData = AppFormValidationData;
  public yearArray: number[];
  public minDate: Date;
  public maxDate: Date;
  public yearInBusinessOptions: EnumValue[] = EnumHelper.getMappedNamesAndValues(TimeInBusinessPeriod, TimeInBusinessPeriodLabel);

  constructor() {
    this.yearArray = this.prepareOptions(50);
    this.minDate = this.getDateMovedYears(-100);
    this.maxDate = this.getDateMovedYears(-16);
  }

  private prepareOptions(years: number): number[] {
    let yearOptions = Array.from(Array(years).keys()).map((i) => {
      return new Date().getFullYear() - i;
    });
    return yearOptions;
  }

  private getDateMovedYears(years: number): Date {
    const result = new Date();
    result.setFullYear(result.getFullYear() + years);
    return result;
  }
}
